import { ICargo } from "./Cargo";

export interface IShipment {
    id: number,
    
    reference: string,
    vehicleReference: string,
    vehicleName: string,
    estimatedDeparture: Date,
    departure: Date,
    estimatedArrival: Date,
    arrival: Date,
    departureLocationCode: string,
    arrivalLocationCode: string,
    
    issueDate: Date,
    description: string,
    status: string,
    statusDate: Date,
    statusPurpose: string,

    type: 'sea' | 'air',

    departureLocation: string,
    arrivalLocation: string,
    
    cargos: ICargo[]
  }


 
  export const defaultShipment : IShipment = {
    id: 0,
    reference: '',
    vehicleReference: '',
    vehicleName: '',
    estimatedDeparture: new Date(),
    departure: new Date(),
    estimatedArrival: new Date(),
    arrival: new Date(),
    departureLocationCode: '',
    arrivalLocationCode: '',   
    
    
    issueDate: new Date(),
    description: '',
    status: '95',
    statusDate: new Date(),
    statusPurpose: '',

    type: 'sea',

    departureLocation: '',
    arrivalLocation: '',

    cargos: []
  }

  
  export interface IShipmentSearch {
    startDate: Date, 
    endDate: Date,

    reference: string,
    description: string,

    vehicleReference: string,
    vehicleName: string
  }