
export interface ITellerOperation {
    id: number,

    customerId: number,
    currencyCode: string,
    
    
    amount: number,
    operationDate: Date,
    issueDate: Date,
    bearer: string,
    reference: string,
    isCancelled: boolean,

    paymentSourceCode: string,

    cancellationDate: Date,
    cancellationPurpose: string,
    isCancelledAtDayClosing: boolean,
    tellerDayClosingId: number,

    tellerOperationBillings: ITellerOperationBilling[],
    tellerOperationModes: ITellerOperationMode[],

    firstName: string,
    lastName: string,  
    corporationName: string  
  }

  export interface ITellerOperationBilling {
    id: number,
    tellerOperationId: number,

    billingId: number,   
    amount: number,
    
    billingAmount: number,
    billingAmountPaid: number,
    billingDueAmount: number,
    billingUnpaidAmount: number,

    billingIssueDate: Date,
    billingReference: string,
    customerName: string
  }

  export interface ITellerOperationMode {
    id: number,
    tellerOperationId: number,
 
    amount: number,
    reference: string,

    type: string,

    checkBankCode: string,
    checkNumber: string,
    checkDate: Date,
    operatorCode: string,
    phoneNumber: string,
    cartType: string,
    cartNumber: string,
    transferFromBankCode: string,
    transferFromToCode: string,
    transferFromAccountNumber: string,
    transferToAccountNumber: string,
    otherReference: string,
    
    paymentOperationId: number,

    xPaymentID: string,
    payerID: string,
    falicitatorAccessToken: string,
    subscriptionID: string,
  }


  export const defaultTellerOperation : ITellerOperation = {
    id: 0,
    
    customerId: 0,
    currencyCode: '',

    amount: 0,
    operationDate: new Date(),
    issueDate: new Date(),
    bearer: '',
    reference: '',
    isCancelled: false,

    paymentSourceCode: '',
    
    cancellationDate: new Date(),
    cancellationPurpose: '',

    isCancelledAtDayClosing: false,
    tellerDayClosingId: 0,

    tellerOperationBillings: [],
    tellerOperationModes: [],

    firstName: '',
    lastName: '',

    corporationName: ''
  }

  export const defaultTellerOperationEx = {...defaultTellerOperation, isArticle: true}


  export interface ITellerOperationSearch {
    startDate: Date,
    endDate: Date,

    firstName: string,
    lastName: string,

    reference: string,
    bearer: string
  }