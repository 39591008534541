
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BoltIcon from '@mui/icons-material/Bolt';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IShipment, defaultShipment } from './models/Shipment';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useShipmentService, { useBasicFilterShipment } from './services/Shipment';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { FormHelperText, IconButton, InputAdornment, Link, List, ListItem, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService , { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';
import IEnumeration, { Enum_ARRIVAL_LOCATION, Enum_DEPARTURE_LOCATION, Enum_OPERATION_STATUS, IEnumerationItem } from 'features/configuration/models/Enumeration';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';
import { defaultCargo, ICargo } from './models/Cargo';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';
import { isFalsy } from 'utility-types';

export const ShipmentForm: FC<IShipment> = (props: IShipment = defaultShipment) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { createShipment, updateShipment } = useShipmentService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterShipment = useBasicFilterShipment( 
    (event: React.MouseEvent<unknown>, row: IShipment) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

    const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
      enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
      onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IShipment>({defaultValues:defaultShipment});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  // let { fields: cargos, append: appendCargos, update: updateCargo ,remove: removeCargo,  } = 
  //                     useFieldArray({ name: 'cargos', control, });

  const watchType = watch('type');
  const watchDepartureLocationCode = watch('departureLocationCode');
  const watchArrivalLocationCode = watch('arrivalLocationCode');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IShipment>,Error,IShipment>(
      _id>0?updateShipment:createShipment, {   
        onSuccess: (data: IResult<IShipment>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Shipment')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Shipment',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IShipment>(['Shipment', _id], () => retrieveEntity('Shipment',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Shipment'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_OPERATION_STATUS, Enum_DEPARTURE_LOCATION, Enum_ARRIVAL_LOCATION ] ));


    const getStatusCode = (row: ICargo, cellId: keyof ICargo, 
        opts: {value: string|number, name: string}[]) : {value: string|number, name: string}[]=> {        
        
          return getAsOptions(refEnumItems.current ?? [],Enum_OPERATION_STATUS);
      }

  const cellEditableCargo = (row: ICargo, cellId: keyof ICargo) => {      
    return true;
  }

  const [headCargoCells, setHeadCargoCells]  = useState<HeadCell<ICargo>[]>([]);
  useEffect(() => {
    setHeadCargoCells([   
      {id:'reference', label : t('Reference'),  display: true, type: 'string', width: 10, isEditable: cellEditableCargo},   
      {id:'businessNumber', label : t('Bus. Number'),  display: true, type: 'string', width: 10, isEditable: cellEditableCargo}, 
        
      {id:'containerNumber', label : t('Container num.'),  display: true, type: 'string', width: 15, isEditable: cellEditableCargo}, 
      {id:'ladingNumber', label : t('Lading'),  display: true, type: 'string', width: 15, isEditable: cellEditableCargo}, 

      {id:'containerLoadingDate', label : t('Loading'),  display: true, type: 'date', width: 10, isEditable: cellEditableCargo}, 
      {id:'customsClearanceDate', label : t('Customs clear.'),  display: true, type: 'date', width: 10, isEditable: cellEditableCargo}, 
      //{id:'description', label : t('Description'),  display: true, type: 'string', width: 25, isEditable: cellEditableCargo}, 
      {id:'volume', label : t('Volume'),  display: true, type: 'numeric', decimalScale:5, width: 10, isEditable: cellEditableCargo}, 
      {id:'weight', label : t('Weight'),  display: true, type: 'numeric', decimalScale:5, width: 10, isEditable: cellEditableCargo}, 

      {id:'status', label : t('Status'),  display: true, type: 'string', width: 10, isEditable: cellEditableCargo, getOptions: getStatusCode}
    ]);
  }, [t,i18n]);
      

  const refAppendCargos = useRef<(value: Partial<FieldArray<IShipment>> | Partial<FieldArray<IShipment>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateCargo = useRef<(index: number,value: Partial<FieldArray<IShipment>> ) => void>(null);
  const refRemoveCargo = useRef<(index: number ) => void>(null);
  
  const cargoRowActionIcon = ( row: ICargo) : ActionIconTableRow<IShipment,ICargo> => {
          
    const res: ActionIconTableRow<IShipment,ICargo> = {
      toolTip: 'viewDetails',
      icon: RemoveCircleIcon,
      hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ICargo) => {
        
        (refRemoveCargo.current??emptyFunc)(index);                        
      }
    }
    return res;
}

        const handleAddCargos = (event: any) => {
          (refAppendCargos.current??emptyFunc)({...defaultCargo});
          
        }

        const handleSelectCargo = async (cargoId: number) => {
          
        }

        const refEnumItems = useRef<IEnumerationItem[]>();    
          useEffect( () => {   
              refEnumItems.current = enumItems;
            
          }, [enumItems])

      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Shipment'));
        setCurrentBasicTextFilterProps(basicFilterShipment);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Shipment',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);

            setCurrentFormNameAtom(`${t('Shipment')} - # ${_data.id} # `);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultShipment);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('Shipment', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.reference.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Shipment', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['Shipment',_id]);        
      await retrieveData('Shipment',_id, refetch);        
      reset(_data);      
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Controller name='type' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="type"
                                      label={t('Data type')} inputProps={ {readOnly: false}}>
                                      <MenuItem key='sea' value='sea'>{t('Maritime')}</MenuItem>
                                      <MenuItem key='air' value='air'>{t('Air_')}</MenuItem> 
                                    </TextField>
                                    )}
                                  />
                                
                                <TextField sx={{width:'calc(15% - 8px)'}} id="reference" label={t('Reference')} 
                                    {...register('reference')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="description" label={t('Description')} 
                                    {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <Controller name='status' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="status"
                                          label={t('Status')} inputProps={ {readOnly: true}} >
                                          {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                <TextField sx={{width:'calc(15% - 8px)'}} id="vehicleName" label={t('Vehicle name')} 
                                    {...register('vehicleName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(15% - 8px)'}} id="vehicleReference" label={t('Vehicle reference')} 
                                    {...register('vehicleReference')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />      
                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='departureLocationCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="departureLocation"
                                      label={t('Departure location')} inputProps={ {readOnly: false}}
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_DEPARTURE_LOCATION, 
                                                                t('Departure location'), t('Departure location'))}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchDepartureLocationCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_DEPARTURE_LOCATION, t('Departure location'), t('Departure location'),
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_DEPARTURE_LOCATION && x.code === watchDepartureLocationCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      } >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_DEPARTURE_LOCATION ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='estimatedDeparture' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Estimated departure')} 
                                      onChange={onChange}                  
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                    /> )}
                                />
                                <Controller control={control}
                                  name='departure' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Departure')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                    /> )}
                                />
                                <Controller name='arrivalLocationCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="arrivalLocation"
                                      label={t('Arrival location')} inputProps={ {readOnly: false}} 
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_ARRIVAL_LOCATION, 
                                                                t('Arrival location'), t('Arrival location'))}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchArrivalLocationCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_ARRIVAL_LOCATION, t('Arrival location'), t('Arrival location'),
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_ARRIVAL_LOCATION && x.code === watchArrivalLocationCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_ARRIVAL_LOCATION ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='estimatedArrival' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Estimated arrival')} 
                                      onChange={onChange}                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                    /> )}
                                />
                                <Controller control={control}
                                  name='arrival' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Arrival')} 
                                      onChange={onChange}                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                    /> )}
                                />
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'  >
                        <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                          <ArrayFieldTableEx<IShipment,ICargo,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headCargoCells} rowsPathName={`cargos`}
                              title={t('Informations')} rowActionIcon={cargoRowActionIcon}  
                              //onRowSelected={handleBillingDetailSelected}
                              //onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                  
                              refAppend={refAppendCargos as MutableRefObject<(value: Partial<FieldArray<IShipment>> | Partial<FieldArray<IShipment>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateCargo as MutableRefObject<(index: number,value: Partial<FieldArray<IShipment>>) => void>}
                              refRemove={refRemoveCargo as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={                                
                                [
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddCargos ,icon: AddCircleIcon }, 
                                ]
                              } 
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                            { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                                {...{open: displayEnumerationItemDialog, 
                                      title: '', 
                                      enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                      afterSave: async () => {await refetchEnumerationItems()}}} 
                                />
                            } 
                        </Box>                                                
                      </Stack>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider> 
  )
}

