import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useEffect, useRef, useState } from 'react';
import {ITicket,  ITicketDashboardDay, INyxisDashboardDay,  ITicketSearch } from "../models/Ticket";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';

import { addMonths, addDays } from 'date-fns';
import { currentUserSessionAtom } from 'library/store';
import { useRecoilValue } from 'recoil';

import useEnumerationService from 'features/configuration/services/Enumeration';
import { useQuery } from 'react-query';
import { Enum_OPERATION_STATUS, Enum_TICKET_RESOLUTION, IEnumerationItem } from 'features/configuration/models/Enumeration';



const _ = () => {

    const axios = useAxios();     

    const createTicket = async (ticket: ITicket)  =>       
        await (await axios.post('/api/production/ticket/create', ticket)).data;       
        
    const updateTicket = async (ticket: ITicket)  =>       
        await (await axios.post('/api/production/ticket/update', ticket)).data; 

    const attachMessageToTicket = async (request: any)  =>       
        await (await axios.post('/api/production/ticket/attach-message-to-ticket', request)).data;  
    
    const getTicket = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/ticket/get-ticket/${id}`));
      return await data;
    }

    const getTickets = async (onlyAssigned: boolean, criteria: ITicketSearch, pagination?: IPagination) : Promise<ITicket[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {statusLevel, startDate, endDate, title, description, personName} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const {data} = (await axios.get(`/api/production/ticket/get-tickets?onlyAssigned=${onlyAssigned}&statusLevel=${statusLevel}&description=${description}&title=${title}&` + 
                      `sStartDate=${sStartDate}&sEndDate=${sEndDate}&personName=${personName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getLastActiveTicketsForMessage = async (contractMessageId: number) : Promise<ITicket[]> => {
      
      const {data} = (await axios.get(`/api/production/ticket/get-last-active-tickets-for-message?contractMessageId=${contractMessageId}`));
      return await data;
    }

    const getDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<ITicketDashboardDay> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/ticket/get-ticket-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }

    const getNyxisDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<INyxisDashboardDay> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/ticket/get-nyxis-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }
      
    return {    
      createTicket,
      updateTicket,

      attachMessageToTicket,

      getTicket,
      getTickets,
      
      getLastActiveTicketsForMessage,

      getDayDashboard,
      getNyxisDayDashboard
    } 
}

export default _;


export const useBasicFilterTicket = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITicket) => void  ) => {

  const { getTickets } = _();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup, roleEntities } = useRecoilValue(currentUserSessionAtom);
  const navigate = useNavigate();

  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Ticket', 'Service'], () => 
        getEnumerationItemsByEnumerationCodes( [ Enum_TICKET_RESOLUTION, Enum_OPERATION_STATUS ] ));

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);

  const getStatus = (row: ITicket, cellId: keyof ITicket, 
    opts: {value: string|number, name: string}[]) => {
      return getAsOptions(refEnumItems.current ?? [],Enum_OPERATION_STATUS);
  }

  const getResolution = (row: ITicket, cellId: keyof ITicket, 
    opts: {value: string|number, name: string}[]) => {
      return getAsOptions(refEnumItems.current ?? [],Enum_TICKET_RESOLUTION);
  }

  const [headTicketCells, setHeadTicketCells]  = useState<HeadCell<ITicket>[]>([
    {id:'title', label : t('Title'),  display: true, type: 'string', width: 25 },
    //{id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'personFullNameFR', label : t('Client'),  display: lg.includes('fr'), type: 'string', width: 25 },
    {id:'personFullNameEN', label : t('Client'),  display: lg.includes('en'), type: 'string', width: 25 },
    {id:'issueDate', label : t('Date'),  display: true, type: 'date', width: 10 },

    {id:'status', label : t('Status'),  display: true, type: 'string', getOptions: getStatus, width: 20 },
    {id:'resolutionCode', label : t('Level'),  display: true, type: 'string', getOptions: getResolution, width: 20 },
  ]); 

  const [filterElements, ] = useState<ITextFilterElement[]>([       
      {name: 'statusLevel', text: `${t('Status')}-${t('Level')}`, value: '00' , dataType: 'enumeration', 
        options: [ 
                  {name: `${t('Current')} (${t('Validated')} / ${t('Not validated')})`, value: '00'},
                  {name: t('Not validated'), value: '95'},
                  {name: t('Validated'), value: '10'},
                  {name: t('Cancelled'), value: '80'},
                  {name: t('Not validated cancelled'), value: '85'},
                  {name: t('All'), value: '_'}, 
                  ]
        },

        {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
        {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},
        {name: 'personName', text: t('Customer'), value: ''}, 
        {name: 'title', text: t('Title'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''}, 
        
        
         
      ]);

  const [filteredTickets, ] = useState<ITicket[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<ITicket[]> => {

    const onlyAssigned = !roleEntities.some(e => e.entityName === 'Ticket');
    console.log(onlyAssigned);
    const statusLevel = filterElements.find( elt => elt.name === 'statusLevel')?.value || '00';

    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();
            
    const title = filterElements.find( elt => elt.name === 'title')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
   
    const personName = filterElements.find( elt => elt.name === 'personName')?.value || '';
    
    const arr = await getTickets(onlyAssigned, {statusLevel, startDate, endDate, title, description, personName}, pagination );
    
    return arr;
  }

  const objKey: keyof ITicket = 'id';

  return {
    title: t('Ticket'), headCells: headTicketCells, objKey,
    filterElements, rows: filteredTickets, 
    onFilterButtonClick, onRowDoubleClick
  }
}

