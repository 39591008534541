
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreIcon from '@mui/icons-material/More';
import ReorderIcon from '@mui/icons-material/Reorder';

import entityService, { useBasicFilterEntity, useBasicFilterFeatureDescription } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IFeatureDescription, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, ClickAwayListener, FormControl, FormControlLabel,FormHelperText,Link, FormGroup, IconButton, InputAdornment, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import CodeIcon from '@mui/icons-material/Code';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { ListItemIcon } from '@mui/material';

import TextFieldRight from 'components/ui/TextFieldRight';

import { stripHtml } from "string-strip-html";

import { AiOutlineMessage, AiOutlineWhatsApp, AiOutlineFacebook, AiOutlineFileExcel, AiOutlineFileWord, AiOutlineLayout } from 'react-icons/ai';

import { HiOutlineDocumentReport } from 'react-icons/hi';

import { isFalsy } from 'utility-types';
import DocViewer, { PDFRenderer, PNGRenderer, MSDocRenderer ,DocViewerRenderers} from '@cyntler/react-doc-viewer';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useJobService, { useBasicFilterJob } from './services/Job';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { IJob, defaultJob, IJobSchedule, defaultJobSchedule, 
  JobType, DateType, JobScheduleType, IJobScheduleParameter, 
  IJobScheduleStep, defaultJobScheduleStep, IJobScheduleStepReportTable, IJobScheduleStepReportTableColumn, IJobScheduleStepReportTableParameter } from './models/Job';

import { globalConfig } from 'config';
import { RichWysiwygEditor } from 'components/ui/RichWysiwygEditor';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';

import useEnumerationService from 'features/configuration/services/Enumeration';
import useApplicationQueryService, { useBasicFilterApplicationQuery } from 'features/setup/services/ApplicationQuery';

import { IEnumerationItem, Enum_JOB_RETENTION_MODE,Enum_DURATION_UNIT, Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT } from 'features/configuration/models/Enumeration';
import { IBusinessApplication, IApplicationQuery, IApplicationQueryParameter, toBaseType } from 'features/setup/models/ApplicationQuery';
import { IReport } from 'features/configuration/models/Report';
import useReportService , { useBasicFilterReport } from 'features/configuration/services/Report';

import { exportToExcel } from 'library/xlsx-export';
import { justifyCenter } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';
import { typeImplementation } from '@testing-library/user-event/dist/type/typeImplementation';

//import * from '../../../public'



export const JobForm: FC<IJob> = (props: IJob = defaultJob) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, getServerTime,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { createJob, updateJob, getJobScheduleQueryExecuteExport } = useJobService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();
  const {getApplicationQueryParameters } = useApplicationQueryService();
  const {getReportTablesByReport, getReportTableColumnsByReport, getReportTableColumnsByReportTable} = useReportService();


  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterJob = useBasicFilterJob( 
    (event: React.MouseEvent<unknown>, row: IJob) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  type QueryUsage = 'mainFilter' | 'substitute' | 'subject';
  const [currentQueryUsage, setCurrentQueryUsage] = useState<QueryUsage>('mainFilter');

  const [openApplicationQueryFilter, setOpenApplicationQueryFilter] = useState(false);
    const basicFilterApplicationQuery = useBasicFilterApplicationQuery( 
            async (event: React.MouseEvent<unknown>, row: IApplicationQuery) => {
        const {id, name } = row;
        //setJobScheduleStepReportTableIndex(index);
        
        if(currentQueryUsage === 'mainFilter') { //jobScheduleStepReportTableIndex === -963
          setValue('applicationQueryId', id);
          setValue('applicationQueryName', name);
          if(jobScheduleIndex >= 0 && jobScheduleIndex < getValues().jobSchedules.length)
            jobScheduleSelected(jobScheduleIndex);

        } else {
          
          if(jobScheduleIndex < 0 || jobScheduleStepIndex < 0 || jobScheduleStepReportTableIndex < 0)
            return;

          const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex); 
          if(isFalsy(jobSchedule)) return;
          const jobScheduleStep = jobSchedule?.jobScheduleSteps.at(jobScheduleStepIndex);
          if(isFalsy(jobScheduleStep)) return;
          const jobScheduleStepReportTable = jobScheduleStep?.jobScheduleStepReportTables.at(jobScheduleStepReportTableIndex);
          if(isFalsy(jobScheduleStepReportTable)) return;

          const parameters = await getApplicationQueryParameters(id);

          (refUpdateJobScheduleStepReportTable.current??emptyFunc)(jobScheduleStepReportTableIndex, 
              {...jobScheduleStepReportTable, applicationQueryId: id, applicationQueryName: name,
                jobScheduleStepReportTableParameters: parameters.map( (param, idx) => ({
                  parameterName: param.parameterName, parameterValueExpression: '', id: 0, 
                  applicationQueryParameterId: param.id, jobScheduleStepReportTableId: jobScheduleStepReportTable.id
                }) )  });
        }
                                
        setOpenApplicationQueryFilter(false);
      }
    );

    const [openEntityFilter, setOpenEntityFilter] = useState(false);
    const basicFilterEntity = useBasicFilterEntity( 
        (event: React.MouseEvent<unknown>, row: IEntity) => {          
            if( isFalsy(row)) return;
            const {name, description} = row;

            if(jobScheduleIndex < 0) return '';

            const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
            if( isFalsy(jobSchedule)) 
              return;
            
            (refUpdateJobSchedule.current??emptyFunc)(jobScheduleIndex, {...jobSchedule,entityName: name!, entityDescription: description! });
                 
            setOpenEntityFilter(false);
        }
    );

    const [openFeatureFilter, setOpenFeatureFilter] = useState(false);
    const basicFilterFeature = useBasicFilterFeatureDescription(
        () => {
            //if(roleEntityIndex < 0 || getValues().roleEntities.length <= roleEntityIndex ) return 'K--@@--K';  
            //return 'getValues().entityName';
            if(jobScheduleIndex < 0) return '';

            const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
            if( isFalsy(jobSchedule)) 
              return '';
            
            return jobSchedule.entityName;
        }, 
        (event: React.MouseEvent<unknown>, row: IFeatureDescription) => {
            if( isFalsy(row)) return;
            const {name, label, entityName} = row;

            const jobSchedule = getValues().jobSchedules[jobScheduleIndex];
            if( isFalsy(jobSchedule)) 
              return;
              
            const jobScheduleStep = jobSchedule.jobScheduleSteps.at(jobScheduleStepIndex);
            if( isFalsy(jobScheduleStep)) 
              return;
  
            updateJobScheduleStep(jobScheduleStepIndex, {...jobScheduleStep, featureName: name!, featureDescription: label! });
            
            //setFilteredFeatures([]);
            setOpenFeatureFilter(false);
        }
    );

    const [openReportFilter, setOpenReportFilter] = useState(false);
    const basicFilterReport = useBasicFilterReport( 
        async (event: React.MouseEvent<unknown>, row: IReport) => {
            if( isFalsy(row)) return;
            const {id, name, entityName ,description} = row;

            const jobSchedule = getValues().jobSchedules[jobScheduleIndex];
            if( jobScheduleStepIndex < 0 || isFalsy(jobSchedule)) 
              return;

            if(entityName !== jobSchedule.entityName) {
              enqueueSnackbar( t('The report selected is not compatible with the entity of this schedule'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
            }
              
            const jobScheduleStep = jobSchedule.jobScheduleSteps.at(jobScheduleStepIndex);
            if(isFalsy(jobScheduleStep)) 
              return;

            const reportTables = await getReportTablesByReport(id);
            const reportTableColumns = await getReportTableColumnsByReport(id);

            updateJobScheduleStep(jobScheduleStepIndex, {...jobScheduleStep, reportId: id!, reportName: name!, 
              jobScheduleStepReportTables: (reportTables || []).map( ({id, name}, idx) => 
                  ({reportTableId: id, tableName: name, id: 0, jobScheduleStepId: 0, applicationQueryId: 0, applicationQueryName: '',
                  jobScheduleStepReportTableColumns: (reportTableColumns || []).filter( rptc => rptc.reportTableId === id)
                    .map( (col, idxParam) => ({alias: col.alias, reportTableColumnId: col.id, jobScheduleStepReportTableId:0, id:0, columnValueExpression: '' }) ),
                    jobScheduleStepReportTableParameters: [] })  )                                                       
                  });
                
            setOpenReportFilter(false);
        }
    );

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState(''); 
  const [expressionField, setExpressionField] = useState<keyof IJobScheduleStep>('reportRecipientExpression');      

  const emptyFunc = (obj: any) => {}
      
  const methods = useForm<IJob>({defaultValues:defaultJob});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  //const watchRetentionMode = watch('retentionMode');
  const watchApplicationQueryId = watch('applicationQueryId');

  const [jobScheduleIndex, setJobScheduleIndex] = useState<number>(-1);
  //const [queryParameters, setQueryParameters] = useState<IApplicationQueryParameter[]>([]);

  const [jobScheduleStepIndex, setJobScheduleStepIndex] = useState<number>(-1);
  const [jobScheduleStepReportTableIndex, setJobScheduleStepReportTableIndex] = useState<number>(-1);
 

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IJob>,Error,IJob>(
      _id>0?updateJob:createJob, {   
        onSuccess: (data: IResult<IJob>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Job',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

      const [enumItems, setEnumItems] = useState<IEnumerationItem[]>([]);

      const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);
      const inputBusinessApplication = useRef();

      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Job'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] ));

    const {data: _data, refetch} = useQuery<IJob>(['Job', _id], () => retrieveEntity('Job',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      // const handleClickSearchEnumeration = (event: any) => {
      //   setOpenEnumerationFilter(true);
      // } 

    const refAppendJobSchedules = useRef<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateJobSchedule = useRef<(index: number,value: Partial<FieldArray<IJob>> ) => void>(null);
    const refRemoveJobSchedule = useRef<(index: number ) => void>(null);

    const handleAddJobSchedule = (event: any) => {

      if(watchApplicationQueryId <= 0) {
        enqueueSnackbar( t('The business application query for filter is not defined'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      const ix = getValues().jobSchedules.length;
      (refAppendJobSchedules.current??emptyFunc)({ ...defaultJobSchedule, description: `## Plan : ${ix}` });
      jobScheduleSelected(ix);
    }

    const handleJobScheduleSelected = (event: React.MouseEvent<unknown>,index: number,row: IJobSchedule) => {      
      
      jobScheduleSelected(index);            
    }

  const jobScheduleSelected = async (index: number) => {
    setJobScheduleIndex(index);

    const {applicationQueryId} = getValues();
    if(applicationQueryId <=0) return;

    const jobSchedule = getValues().jobSchedules.at(index)!;

    const parameters = await getApplicationQueryParameters(applicationQueryId); 
    
      (refUpdateJobSchedule.current??emptyFunc)(index,
        {...jobSchedule,
          jobScheduleParameters: parameters.map( (param, idx) => {
            
            const {parameterName, parameterDataType, id} = param;

            const tmpParameter = jobSchedule.jobScheduleParameters.find( p => p.applicationQueryParameterId === id);
            
            return {  id: tmpParameter?.id ?? 0, 
                      jobScheduleId: tmpParameter?.jobScheduleId ?? 0, 
                      applicationQueryParameterId: id,
                      parameterName,
                      parameterValueExpression: tmpParameter?.parameterValueExpression ??   '',
            }
          } )
        });
  }
  


  const jobScheduleRowActionIcon = ( jobSchedule: IJobSchedule) : ActionIconTableRow<IJob,IJobSchedule> => {  
      const res: ActionIconTableRow<IJob,IJobSchedule> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IJobSchedule) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IJobSchedule) => {           
          
          (refRemoveJobSchedule.current??emptyFunc)(index);            
        }
      }
      return res;
    }
      
    const getJobScheduleTypeList = (row: IJobSchedule, cellId: keyof IJobSchedule, 
      opts: {value: string | number, name: string}[]) => {   
      //const {requestClassEnumerationCode} = row;
      
      return [{value: 'day', name: t('Day')}, {value: 'month', name: t('Month')}, 
              {value: 'unic', name: t('Unic')}, {value: 'recurrent', name: t('Recurrent')}];
    }

      const cellEditableJobSchedule = (row: IJobSchedule, cellId: keyof IJobSchedule) => {
        return true;
      }

      const [dateTypes, setDateTypes]  = useState<{name: string, value: string}[]>([]);
      const [dayOfWeeks, setDayOfWeeks]  = useState<{name: string, value: string}[]>([]);
      const [weekOrders, setWeekOrders]  = useState<{name: string, value: number}[]>([]);
      const [transmissionModes, setTransmissionModes]  = useState<{name: string, value: string}[]>([]);

      const [headJobScheduleCells, setHeadJobScheduleCells]  = useState<HeadCell<IJobSchedule>[]>([]);
      useEffect(() => {
        setHeadJobScheduleCells([            
          {id:'description', label : t('Description'),  display: true, type: 'string', width: 65, isEditable: cellEditableJobSchedule},
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'type', label : t('Type'),  display: true, type: 'string', width: 35, isEditable: cellEditableJobSchedule,
          getOptions: getJobScheduleTypeList}
        ])
      }, [t,i18n]);

      const refAppendJobScheduleParameters = useRef<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateJobScheduleParameter = useRef<(index: number,value: Partial<FieldArray<IJob>> ) => void>(null);
      const refRemoveJobScheduleParameter = useRef<(index: number ) => void>(null);

      const [parameterIndex, setParameterIndex] = useState<number>(-1);
      const jobScheduleParameterRowActionIcon = ( jobScheduleParameter: IJobScheduleParameter) : ActionIconTableRow<IJob,IJobScheduleParameter> => {  
        const res: ActionIconTableRow<IJob,IJobScheduleParameter> = {
          toolTip: 'remove',
          icon: DeveloperModeIcon,
          hasAction: (index: number,row: IJobScheduleParameter) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: IJobScheduleParameter) => {    
                   
            if(jobScheduleIndex < 0) return;

            const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
            if( isFalsy(jobSchedule)) 
              return;
            
            const {entityName} = jobSchedule;
            if(isFalsy(entityName)) {
              enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                  return;
            }
            
            setParameterIndex(index); 
            setReportTableParameterIndex(-963)
            setCurrentEntityName(entityName);
            setExpressionField('jobScheduleStepReportTables');  
            setCurrentExpression(row.parameterValueExpression);  
            setOpenEntityExpression(true);                      
          }
        }
        return res;
      }

      const [headJobScheduleParameterCells, setHeadJobScheduleParameterCells]  = useState<HeadCell<IJobScheduleParameter>[]>([]);
      useEffect(() => {
        setHeadJobScheduleParameterCells([            
          {id:'parameterName', label : t('Name'),  display: true, type: 'string', width: 30, },
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'parameterValueExpression', label : t('Expression'),  display: true, type: 'string', width: 70, }
        ])
      }, [t,i18n]);

      useEffect(() => {
        setDateTypes([{name: t('Day'), value: 'day'}, {name: t('Order'), value: 'order'}]);

        setDayOfWeeks([ {name: t('Monday'), value: 'MON'}, {name: t('Tuesday'), value: 'TUE'}, 
        {name: t('Wednesday'), value: 'WED'}, {name: t('Thursday'), value: 'THU'}, {name: t('Friday'), value: 'FRI'}, 
        {name: t('Saturday'), value: 'SAT'},{name: t('Sunday'), value: 'SUN'}, {name: t('Day'), value: 'DAY'}
        ]);

        setWeekOrders([ {name: t('First'), value: 1}, {name: t('Second'), value: 2}, {name: t('Third'), value: 3},
                        {name: t('Fourth'), value: 4}, {name: t('Last'), value: 5}
        ]);

        setTransmissionModes([ {name: t('Sms'), value: 'sms'}, 
                               {name: t('WhatsApp'), value: 'whatsApp'}, 
                               {name: t('Email'), value: 'email'}
        ]);

      }, [t,i18n]);


      const refAppendJobScheduleStepReportTables = useRef<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateJobScheduleStepReportTable = useRef<(index: number,value: Partial<FieldArray<IJob>> ) => void>(null);
      const refRemoveJobScheduleStepReportTable = useRef<(index: number ) => void>(null);

      const getInputAdornmentReportTable = (row: IJobScheduleStepReportTable, cellId: keyof IJobScheduleStepReportTable)  => ({
 
        toolTip: 'Expression',
        icon: MoreIcon,
        onClickIcon: (event: any, index: number, row: IJobScheduleStepReportTable ) => {
          
          // setCurrentFieldExpression(valueExpression);
          setJobScheduleStepReportTableIndex(index);
          setOpenReportTableColumn(true);
        }  
     })

     const getInputAdornmentReportTableQuery = (row: IJobScheduleStepReportTable, cellId: keyof IJobScheduleStepReportTable)  => ({
 
      toolTip: 'Query',
      icon: ArrowDropDownCircleIcon,
      onClickIcon: (event: any, index: number, row: IJobScheduleStepReportTable ) => {
        
        // setCurrentFieldExpression(valueExpression);
        setJobScheduleStepReportTableIndex(index);
        setOpenApplicationQueryFilter(true);
      }  
   })

      const jobScheduleStepReportTableRowActionIcon = ( jobSchedule: IJobScheduleStepReportTable) : ActionIconTableRow<IJob,IJobScheduleStepReportTable> => {  
        const res: ActionIconTableRow<IJob,IJobScheduleStepReportTable> = {
          toolTip: 'remove',
          icon: ReorderIcon,
          hasAction: (index: number,row: IJobScheduleStepReportTable) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: IJobScheduleStepReportTable) => {           
            
            setJobScheduleStepReportTableIndex(index);
            setOpenReportTableParameter(true);
            //(refRemoveJobSchedule.current??emptyFunc)(index);            
          }
        }
        return res;
      }

      const [headJobScheduleStepReportTableCells, setHeadJobScheduleStepReportTableCells]  = useState<HeadCell<IJobScheduleStepReportTable>[]>([]);
      useEffect(() => {
        setHeadJobScheduleStepReportTableCells([            
          {id:'tableName', label : t('Name'),  display: true, type: 'string', width: 50, 
            getInputAdornment: getInputAdornmentReportTable },
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'applicationQueryName', label : t('Query'),  display: true, type: 'string', width: 50,
            getInputAdornment: getInputAdornmentReportTableQuery }
        ])
      }, [t,i18n]);

      const [openReportTableColumn,setOpenReportTableColumn] = useState<boolean>(false);
      const [reportTableColumnIndex, setReportTableColumnIndex] = useState<number>(-1);

      
      const cellEditableReportTableColumn = (row: IJobScheduleStepReportTableColumn, cellId: keyof IJobScheduleStepReportTableColumn) => {
        return true;
      }

      const jobScheduleStepReportTableColumnRowActionIcon = ( jobScheduleStepReportTableColumn: IJobScheduleStepReportTableColumn) : ActionIconTableRow<IJob,IJobScheduleStepReportTableColumn> => {  
        const res: ActionIconTableRow<IJob,IJobScheduleStepReportTableColumn> = {
          toolTip: 'remove',
          icon: DeveloperModeIcon,
          hasAction: (index: number,row: IJobScheduleStepReportTableColumn) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: IJobScheduleStepReportTableColumn) => {           
            
                      
            if(jobScheduleIndex < 0) return;
            
              const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
              if( isFalsy(jobSchedule)) 
                return;
                
              const {entityName} = jobSchedule;
              if(isFalsy(entityName)) {
                enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                  return;
              }             
              
              setReportTableColumnIndex(index);
              setParameterIndex(-963); 
              setReportTableParameterIndex(-963);
              setCurrentEntityName(entityName);
              setExpressionField('jobScheduleStepReportTables');  
              setCurrentExpression(row.columnValueExpression);  
              setOpenEntityExpression(true);                          
            }
        }
        return res;
      }
      const [headReportTableColumnCells, setHeadReportTableColumnCells]  = useState<HeadCell<IJobScheduleStepReportTableColumn>[]>([]);
      useEffect(() => {
        setHeadReportTableColumnCells([            
          //{id:'name', label : t('Name'),  display: true, type: 'string', width: 50, isEditable: cellEditableReportTable},
          // {id:'format', label : t('Format'),  display: true, type: 'string', width: 25, isEditable: cellEditableReportTable, 
          //   getOptions: getFormatsList },
          {id:'alias', label : t('Alias'),  display: true, type: 'string', width: 35},
          {id:'columnValueExpression', label : t('Expression'),  display: true, type: 'string', width: 65,},
              
          //{id:'extensionTypeDescription', label : t('Type description'),  display: true, type: 'string',} ,  
          //{id:'extensionTypeType', label : t('Type'),  display: true, type: 'string',} ,  
        ]  )
      }, [t,i18n])
  
      const refAppendReportTableColumns = useRef<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateReportTableColumn = useRef<(index: number,value: Partial<FieldArray<IJob>> ) => void>(null);
      const refRemoveReportTableColumn = useRef<(index: number ) => void>(null);


      const handleExportXlsxFile = async (event: any) => {
        
        if(jobScheduleIndex < 0) return;

        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex)!;
        const {data: rows} = await getJobScheduleQueryExecuteExport(jobSchedule.id);
        
        exportToExcel(rows, `test data`);
      }

      let { fields, append: appendJobScheduleStep, update: updateJobScheduleStep ,remove: removeJobScheduleStep,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
        name: `jobSchedules.${jobScheduleIndex}.jobScheduleSteps`,
        control,            
      });


      const [openReportTableParameter, setOpenReportTableParameter] = useState<boolean>(false);

      const refAppendJobScheduleStepReportTableParameters = useRef<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateJobScheduleStepReportTableParameter = useRef<(index: number,value: Partial<FieldArray<IJob>> ) => void>(null);
      const refRemoveJobScheduleStepReportTableParameter = useRef<(index: number ) => void>(null);

      const [reportTableParameterIndex, setReportTableParameterIndex] = useState<number>(-1);
      const jobScheduleStepReportTableParameterRowActionIcon = ( jobScheduleParameter: IJobScheduleStepReportTableParameter) : ActionIconTableRow<IJob,IJobScheduleStepReportTableParameter> => {  
        const res: ActionIconTableRow<IJob,IJobScheduleStepReportTableParameter> = {
          toolTip: 'Expression',
          icon: DeveloperModeIcon,
          hasAction: (index: number,row: IJobScheduleStepReportTableParameter) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: IJobScheduleStepReportTableParameter) => {    
                   
            if(jobScheduleIndex < 0) return;

            const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
            if( isFalsy(jobSchedule)) 
              return;
            
            const {entityName} = jobSchedule;
            if(isFalsy(entityName)) {
              enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                  return;
            }
            
            setReportTableColumnIndex(-963);
            setParameterIndex(-963); 
            setReportTableParameterIndex(index);
            setCurrentEntityName(entityName);
            setExpressionField('jobScheduleStepReportTables');  
            setCurrentExpression(row.parameterValueExpression);  
            setOpenEntityExpression(true);                      
          }
        }
        return res;
      }

      const [headJobScheduleStepReportTableParameterCells, setHeadJobScheduleStepReportTableParameterCells]  = useState<HeadCell<IJobScheduleStepReportTableParameter>[]>([]);
      useEffect(() => {
        setHeadJobScheduleStepReportTableParameterCells([            
          {id:'parameterName', label : t('Name'),  display: true, type: 'string', width: 30, },
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'parameterValueExpression', label : t('Expression'),  display: true, type: 'string', width: 70, }
        ])
      }, [t,i18n]);
      


      const [jobType, setJobType] = useState<JobType>('report');
      const handleChangeJobType = ( event: React.MouseEvent<HTMLElement>, newType: JobType, stpIndex: number ) => {  

        if (newType === null) 
          return;

        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
        if( isFalsy(jobSchedule)) 
          return;
          
        const jobScheduleStep = jobSchedule.jobScheduleSteps.at(stpIndex);
        if( isFalsy(jobScheduleStep)) 
          return;
        
        updateJobScheduleStep(stpIndex, {...jobScheduleStep, type: newType} );        
      }

      const handleDisplayServerTime = async (event: any) => {
        const time = await getServerTime();
        alert(time);
      }

      const handleClickSearchApplicationQuery = (event: any) => {
        //setJobScheduleStepReportTableIndex(-963);
        setCurrentQueryUsage('mainFilter');
        setOpenApplicationQueryFilter(true);
       }

      const handleClickSearchEntity = (event: any, jobIdx: number) => {  
        //setJobScheduleStepIndex(stpIndex);        
        setOpenEntityFilter(true);
      }
 
      const handleClickSearchFeature = (event: any, stpIndex: number) => {  
        setJobScheduleStepIndex(stpIndex);    
        
        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
        if( isFalsy(jobSchedule)) 
          return;
        const { entityName } = jobSchedule;
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }
        setOpenFeatureFilter(true);
      }     
      
      const handleStepDelete = (event: any, stpIndex: number) => { 
        removeJobScheduleStep(stpIndex);        
      }

      const handleClickSearchReport = (event: any, stpIndex: number) => {  
        setJobScheduleStepIndex(stpIndex);    

        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
        if( isFalsy(jobSchedule)) 
          return;
        const { entityName } = jobSchedule;
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }

        setOpenReportFilter(true);
      }

      const handleClickOpenRecipientExpression = (event: any, stpIndex: number, expField: keyof IJobScheduleStep, expression: string) => {  
        setJobScheduleStepIndex(stpIndex);
        
        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
        if( isFalsy(jobSchedule)) 
          return;
          
        const { entityName } = jobSchedule;
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }
        
        const jobScheduleStep = jobSchedule.jobScheduleSteps.at(jobScheduleStepIndex);
        if( isFalsy(jobScheduleStep)) 
          return;
          
        // const { reportRecipientExpression, reportRecipientExpressionCC, reportRecipientExpressionBcc} = jobScheduleStep;
        // if(isFalsy(entityName)) {
        //   enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
        //             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        //       return;
        // }
        
        setCurrentEntityName(entityName);
        setExpressionField(expField);  //setExpressionField('reportRecipientExpression');    
        setCurrentExpression(expression);            
        setOpenEntityExpression(true);
      }

      const handleClickOpenLanguageExpression = (event: any, stpIndex: number) => {  
        setJobScheduleStepIndex(stpIndex);

        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
        if( isFalsy(jobSchedule)) 
          return;

        const {entityName} = jobSchedule;
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }
          
        const jobScheduleStep = jobSchedule.jobScheduleSteps.at(jobScheduleStepIndex);
        if( isFalsy(jobScheduleStep)) 
          return;
        
        const { reportLanguageExpression} = jobScheduleStep;
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }

        setCurrentEntityName(entityName);
        setExpressionField('reportLanguageExpression');  
        setCurrentExpression(reportLanguageExpression);           
        setOpenEntityExpression(true);
      }      
                                                                              
      const handleClickOkExpression = async () => {
        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
        if( isFalsy(jobSchedule)) 
          return;
          
        const jobScheduleStep = jobSchedule.jobScheduleSteps.at(jobScheduleStepIndex);
        // if( isFalsy(jobScheduleStep)) 
        //   return;
          
        const { entityName } = jobSchedule;
        
        const checkExpression = await checkEntityExpressionSyntax(entityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
        
        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

                
        if( ['reportRecipientExpression','reportLanguageExpression', 'reportRecipientExpressionCC', 'reportRecipientExpressionBcc'].includes(expressionField)) {
          if( isFalsy(jobScheduleStep)) 
             return;

          if(returnType !== 'string')
            enqueueSnackbar( t('The result of expression must be string') , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          else
            updateJobScheduleStep(jobScheduleStepIndex, {...jobScheduleStep, [expressionField]: currentExpression });                     
        } 
        else {
            if(parameterIndex >= 0) {
              const parameters = await getApplicationQueryParameters(getValues().applicationQueryId);

              const jobScheduleParameter = jobSchedule.jobScheduleParameters.at(parameterIndex);
              if(isFalsy(jobScheduleParameter)) return;
              const queryParameter = parameters.find(p => p.parameterName === jobScheduleParameter.parameterName);
              
              if(queryParameter) {
                
                if(returnType !== toBaseType(queryParameter.parameterDataType) )
                  enqueueSnackbar( `${t('The result of expression must be')} : ${queryParameter.parameterDataType}` , { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                else
                  (refUpdateJobScheduleParameter.current??emptyFunc)(parameterIndex, 
                      {...jobScheduleParameter, parameterValueExpression: currentExpression});
              }
            } else if(reportTableParameterIndex >= 0) {
              if( isFalsy(jobScheduleStep)) 
                return;

              const jobScheduleStepReportTable = jobScheduleStep.jobScheduleStepReportTables.at(jobScheduleStepReportTableIndex);
              const jobScheduleStepReportTableParameter = jobScheduleStepReportTable?.jobScheduleStepReportTableParameters.at(reportTableParameterIndex);
              if(isFalsy(jobScheduleStepReportTableParameter) || isFalsy(jobScheduleStepReportTable)) return;

              const parameters = await getApplicationQueryParameters(jobScheduleStepReportTable!.applicationQueryId);
              const queryParameter = parameters.find(p => p.parameterName === jobScheduleStepReportTableParameter.parameterName);
              if(queryParameter) {

                
                if(returnType !== toBaseType(queryParameter.parameterDataType) )
                  enqueueSnackbar( `${t('The result of expression must be')} : ${queryParameter.parameterDataType}` , { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                else
                  (refUpdateJobScheduleStepReportTableParameter.current??emptyFunc)(reportTableParameterIndex,
                      {...jobScheduleStepReportTableParameter, parameterValueExpression: currentExpression} );
              }
            } else if(reportTableColumnIndex >= 0) {
              if( isFalsy(jobScheduleStep)) 
                return;

              const jobScheduleStepReportTable = jobScheduleStep.jobScheduleStepReportTables.at(jobScheduleStepReportTableIndex);
              const jobScheduleStepReportTableColumn = jobScheduleStepReportTable?.jobScheduleStepReportTableColumns.at(reportTableColumnIndex);
              if(isFalsy(jobScheduleStepReportTableColumn) || isFalsy(jobScheduleStepReportTable)) return;

              const reportTableColumns = await getReportTableColumnsByReportTable(jobScheduleStepReportTable!.reportTableId);
                            
              const reportTableColumn = reportTableColumns.find(c => c.id === jobScheduleStepReportTableColumn.reportTableColumnId);
              
              if(reportTableColumn) {
                
                const enumItemFormat = enumItems.find(x => x.enumerationCode === Enum_REPORTFIELD_DATA_FORMAT && x.code === reportTableColumn.format );
                
                if(returnType !== enumItemFormat?.parentEnumerationItemCode) {
                  enqueueSnackbar( t('Selected format and return type of expression are incompatible') , { variant: 'error',
                        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                  return;
                }                           

                (refUpdateReportTableColumn.current??emptyFunc)(reportTableColumnIndex, 
                    {...jobScheduleStepReportTableColumn, columnValueExpression: currentExpression });
              }
              
            }           
           
        }        
        
        setOpenEntityExpression(false);
      }

      const [currentJobScheduleSummarizeItems,setCurrentJobScheduleSummarizeItems] = useState<boolean>(false);
      const [currentJobScheduleType,setCurrentJobScheduleType] = useState<JobScheduleType>('day');
      const [currentDateType,setCurrentDateType] = useState<DateType>('day');
      useEffect(() => {
        const subscription = watch( (value, { name, type }) => {
         
          if(isFalsy(value))
            return;
          
          if(jobScheduleIndex < 0 || jobScheduleIndex >= getValues().jobSchedules.length)
            return;

          if(value && value.jobSchedules ) {
                
                const jobSchedule = value.jobSchedules.at(jobScheduleIndex);
                
                if(jobSchedule) {
                  
                  setCurrentJobScheduleType(jobSchedule.type || getValues().jobSchedules[jobScheduleIndex].type); 
                  
                  setCurrentDateType(jobSchedule.dateType || getValues().jobSchedules[jobScheduleIndex].dateType); 

                  setCurrentJobScheduleSummarizeItems(jobSchedule.summarizeItems || getValues().jobSchedules[jobScheduleIndex].summarizeItems);
                  
                  const jobScheduleStep = (jobSchedule.jobScheduleSteps || []).at(jobScheduleStepIndex);
                  
                  if(jobScheduleStep) {
                    setCurrentRetentionMode( jobScheduleStep?.retentionMode || 'PERSO');  
                    setCurrentTranmissionMode( jobScheduleStep?.reportTransmissionMode || 'sms');
                  }
                                      
                }
              }
        });  
      
        return () => subscription.unsubscribe();
      }, [watch, jobScheduleIndex, jobScheduleStepIndex ]);

      // useEffect( () => {        
      //   async function loadApplicationQueryParameters() {
      //     if(watchApplicationQueryId > 0) {
      //       const arr = await getApplicationQueryParameters(watchApplicationQueryId);         
      //       setQueryParameters([...arr]);
      //     }          
      //   }
  
      //   loadApplicationQueryParameters();
      // }, [watchApplicationQueryId]); 
      
      useEffect( () => {
        async function loadEnumerationItems() {
          const arr = await getEnumerationItemsByEnumerationCodes( [Enum_JOB_RETENTION_MODE,Enum_DURATION_UNIT, Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] );
          setEnumItems([...arr]);  
        }
  
        loadEnumerationItems();
      }, [])
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Job schedule'));
        setCurrentBasicTextFilterProps(basicFilterJob);
      }, []); 

      
      const [valueStep, setValueStep] = useState<number>(0);

      const handleStepChange = (event: React.SyntheticEvent, newValue: number) => {        
        setValueStep(newValue);
        setJobScheduleStepIndex(newValue);
      };

      const handleClickAddStep = (event: any) => {
        const jobSchedule = getValues().jobSchedules.at(jobScheduleIndex);
        if( isFalsy(jobSchedule)) 
          return;
        const { entityName } = jobSchedule;
        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
        }

        appendJobScheduleStep( {...defaultJobScheduleStep} );
      }

      const [currentRetentionMode,setCurrentRetentionMode] = useState('NONE');
      const [currentTranmissionMode,setCurrentTranmissionMode] = useState('sms');
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Job',_id, refetch);  
          }, [_id] );
    
    
      useEffect( () => {        
        
        if(_data && _data.id > 0) {
           
            if(_data.jobSchedules.length > 0) {
              setJobScheduleIndex(0);
              if(_data.jobSchedules[0].jobScheduleSteps.length > 0)
                setJobScheduleStepIndex(0);
            }
            reset({..._data });
            //setJobType(_data.type);
            
          }
          
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
        _setId(0);       
        reset(defaultJob);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
        if(!checkEntitySaveAuthorization('Job', _id)) {
          setIsSaveLoading(false);
          return;
        }
        
        const data = getValues(); 
        if(data.name.trim() === '' || data.description.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }
          
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Job', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

    

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.25} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(65% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                      label={t('Active ?')}
                                      control={
                                      <Controller
                                          name='isActive'
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />                                
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column' >
                          <Box sx={{ mt: 1, width: '100%' }}  >
                            <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                          </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column' >
                          <Box sx={{ mt: 1, width: '100%' }}  >
                              
                                <TextField sx={{width:'calc(100% - 8px)'}} id="applicationQueryName" label={t('Query filter')} {...register('applicationQueryName')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchApplicationQuery}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                { openApplicationQueryFilter && <FormDialog open={openApplicationQueryFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Query filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenApplicationQueryFilter(false);}} onOk={()=> {setOpenApplicationQueryFilter(false);}}  >
                                    <BasicTextFilterForm<IApplicationQuery> {...basicFilterApplicationQuery} />
                                  </FormDialog>  }
                                  { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }
                          </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column' >
                            <Box sx={{ mt: 1, width: '100%' }}  >                                
                              <ArrayFieldTableEx<IJob,IJobSchedule,'id'> 
                                
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headJobScheduleCells} rowsPathName='jobSchedules' 
                                title={t('Schedules')} rowActionIcon={jobScheduleRowActionIcon}  
                                onRowSelected={handleJobScheduleSelected}
                                                    
                                refAppend={refAppendJobSchedules as MutableRefObject<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateJobSchedule as MutableRefObject<(index: number,value: Partial<FieldArray<IJob>>) => void>}
                                refRemove={refRemoveJobSchedule as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddJobSchedule ,icon: AddCircleIcon,  },
                                
                                ]}
                              /> 
                           </Box>
                        </Stack>                        
                    </Grid>     
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'  >
                        {
                          getValues().jobSchedules.map( (jobSchedule, index) => {
                            return (index === jobScheduleIndex) && (
                              <Fragment key={`key-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                <Box sx={{ mt: 1, width: '100%' }} > 
                                  <Typography  variant="h6" id={`key-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`} component="div" color="primary" noWrap >
                                    {`${t(('Schedule'))} - ${(jobScheduleIndex+1)} - ${jobSchedule.description}`}
                                  </Typography>                                                       
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller
                                        key={`query-${jobSchedule.description}-query-x`}
                                        render={({ field }) => <TextField label={t('Description')} {...field}  
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`, textTransform: 'none'}} 
                                           />} 
                                        name={`jobSchedules.${index}.description`}                      
                                        control={control}
                                      />                                              
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller
                                    key={`jobSchedules-${jobSchedule.id}-itemEntityQuery-x-${jobSchedule.entityDescription}`}
                                    render={({ field }) => <TextField label={t('Entity')} {...field} 
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`}}
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={(event) => handleClickSearchEntity(event, index)}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />}
                                    name={`jobSchedules.${index}.entityDescription`}                      
                                    control={control}
                                  />
                                    { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='md'
                                        okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                        onClose={()=> {setOpenEntityFilter(false);}} onOk={()=> {setOpenEntityFilter(false);}}  >
                                            <BasicTextFilterForm<IEntity> {...basicFilterEntity } />
                                    </FormDialog> }                                          
                                </Box> 
                                <Box sx={{ mt: 1, width: '100%' }} > 
                                  <Controller 
                                      key={`jobSchedules-${jobSchedule.id}-IsStepByItem-x-${jobSchedule.summarizeItems}`}                                    
                                      name={`jobSchedules.${jobScheduleIndex}.summarizeItems`}
                                      control={control}
                                      render= {({ field: {value, onChange,...props} }) => <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                          label={t('Summarize items ?')}
                                            control={<Checkbox {...props} checked={value} onChange={onChange} />
                                            } /> }                                      
                                    />
                                </Box>
                                { !currentJobScheduleSummarizeItems && <Box sx={{ mt: 1, width: '100%' }} > 
                                  <Controller 
                                      key={`jobSchedules-${jobSchedule.id}-IsStepByItem-x-${jobSchedule.isStepByItem}`}                                    
                                      name={`jobSchedules.${jobScheduleIndex}.isStepByItem`}
                                      control={control}
                                      render= {({ field: {value, onChange,...props} }) => <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                          label={t('Run all step item by item ?')}
                                            control={<Checkbox {...props} checked={value} onChange={onChange} />
                                            } /> }                                      
                                    />
                                </Box> }
                                <Box sx={{ mt: 1, width: '100%' }} >                                   
                                  <Controller
                                    key={`query-${jobSchedule.startDate}-startDate-x`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Start date')}        
                                        onChange={onChange}                        
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(35% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:`calc(40% - 8px)`}}  />}
                                      /> )}
                                    name={`jobSchedules.${index}.startDate`}          
                                    control={control}
                                  />
                                  <Controller
                                    key={`query-${jobSchedule.executionTime}-executionTime-x`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <FormControl sx={{ width: 'calc(35% - 8px)' }}>
                                        <TimePicker label={t('Time')}
                                          format="HH:mm:ss"    
                                          views={['hours', 'minutes', 'seconds']}     
                                          onChange={onChange}                        
                                          value={new Date(value)}
                                          slotProps={{ textField: { sx: {width:'calc(100% - 8px)'}  }} }
                                          
                                          //renderInput={(params) => <TextField {...params} sx={{width:`calc(30% - 8px)`, textAlign: 'center'}} />}
                                        />
                                        <FormHelperText>
                                          <Link href="#" onClick={handleDisplayServerTime}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Server time')}
                                          </Link>
                                        </FormHelperText>
                                      </FormControl> )}
                                    name={`jobSchedules.${index}.executionTime`}          
                                    control={control}
                                  />
                                  <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                      label={t('Active ?')}
                                      control={
                                      <Controller
                                          name={`jobSchedules.${index}.isActive`} 
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />
                                </Box>   
                                {currentJobScheduleType === 'recurrent' && <Box sx={{ mt: 1, width: '100%' }} >                                   
                                  <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                      label={t('Has end time ?')}
                                      control={
                                      <Controller
                                          name={`jobSchedules.${index}.hasEndExecutionTime`} 
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />
                                  <Controller
                                    key={`query-${jobSchedule.endExecutionTime}-endExecutionTime-x`}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <TimePicker label={t('Time')}  
                                        format="HH:mm:ss"    
                                        views={['hours', 'minutes', 'seconds']}   
                                        onChange={onChange}                        
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:`calc(30% - 8px)`, textAlign: 'center'}} />}
                                      /> )}
                                    name={`jobSchedules.${index}.endExecutionTime`}          
                                    control={control}
                                  />                                  
                                </Box> }
                                {currentJobScheduleType === 'day' && <Box>
                                  <FormGroup>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Monday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isMonday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Tuesday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isTuesday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('Wednesday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isWednesday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Thursday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isThursday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Friday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isFriday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('Saturday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isSaturday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('Sunday')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isSunday`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                  </FormGroup>
                                </Box>}   
                                {currentJobScheduleType === 'month' && <Box id={`key-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                  <FormGroup id={`key-month-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('January')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isJanuary`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('Febuary')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isFebuary`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                    />
                                    <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('March')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isMarch`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>                                      
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('April')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isApril`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                            label={`${t('May')} ?`}
                                            control={
                                            <Controller
                                                name={`jobSchedules.${index}.isMay`} 
                                                control={control}
                                                render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                            />} 
                                        />
                                        <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('June')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isJune`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>                                    
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('July')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isJuly`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('August')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isAugust`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                          label={`${t('September')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isSeptember`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>                                      
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                        label={`${t('October')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isOctober`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(33% - 8px)', marginTop: '2px'}}
                                          label={`${t('November')} ?`}
                                          control={
                                          <Controller
                                              name={`jobSchedules.${index}.isNovember`} 
                                              control={control}
                                              render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} 
                                      />
                                      <FormControlLabel sx={{width:'calc(34% - 8px)', marginTop: '2px'}}
                                        label={`${t('December')} ?`}
                                        control={
                                        <Controller
                                            name={`jobSchedules.${index}.isDecember`} 
                                            control={control}
                                            render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                        />} 
                                      />
                                    </Box>                                    
                                    <Box sx={{ mt: 1, width: '100%' }} id={`key-xxx-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                      <Controller name={`jobSchedules.${index}.dateType`}
                                        control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id={`jobSchedules.${jobSchedule.dateType}.dateType`}
                                            label={t('Date')} >
                                              { dateTypes.map(({name, value}, idx) => (<MenuItem key={value} value={value}>{name}</MenuItem>))}                                              
                                          </TextField>
                                        )}
                                      />  
                                      { currentDateType === 'day' &&<Controller
                                          render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                          return (
                                            <NumberFormat              
                                              disabled={false}
                                              label={t('Day')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                              allowEmptyFormatting={false}
                                              control={control}    
                                              thousandSeparator={true}
                                              decimalScale={0}
                                              onValueChange={ (v) => onChange(v.floatValue) }
                                              defaultValue={value}
                                              value={value}

                                              customInput={TextFieldRight}                            
                                            />
                                          );
                                        }}
                                        name={`jobSchedules.${index}.dayOfMonth`}
                                        control={control}
                                      /> }
                                      { currentDateType === 'order' && <Controller name={`jobSchedules.${index}.weekOrder`}                                        
                                        control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id={`jobSchedules.${jobSchedule.dateType}.weekOrder`}
                                            label={t('Order')} >
                                              { weekOrders.map(({name, value}, idx) => (<MenuItem key={value}  value={value}>{name}</MenuItem>))}                                              
                                          </TextField>
                                        )}
                                      /> }
                                      { currentDateType === 'order' && <Controller name={`jobSchedules.${index}.dayOfWeek`}
                                        control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id={`jobSchedules.${jobSchedule.dateType}.dayOfWeek`}
                                            label={t('Day of week')} >
                                              { dayOfWeeks.map(({name, value}, idx) => (<MenuItem key={value}  value={value}>{name}</MenuItem>))}                                              
                                          </TextField>
                                        )}
                                      /> }
                                    </Box>
                                  </FormGroup>
                                </Box>}                          
                                <Box sx={{ mt: 1, width: '100%' }} key={`parameters-${watchApplicationQueryId}`} id={`parameters-${watchApplicationQueryId}`}> 
                                  <ArrayFieldTableEx<IJob,IJobScheduleParameter,'id'> 
                                      key={`${jobSchedule.jobScheduleParameters.length} parameters-${watchApplicationQueryId} ${jobSchedule.description}`}
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headJobScheduleParameterCells} 
                                      rowsPathName={`jobSchedules.${index}.jobScheduleParameters`} 
                                      title={`${t('Parameters')} - ${jobSchedule.description}`} 
                                      rowActionIcon={jobScheduleParameterRowActionIcon}  
                                      //onRowSelected={handleBusinessAppCodificationSelected}
                                                          
                                      refAppend={refAppendJobScheduleParameters as MutableRefObject<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateJobScheduleParameter as MutableRefObject<(index: number,value: Partial<FieldArray<IJob>>) => void>}
                                      refRemove={refRemoveJobScheduleParameter as MutableRefObject<(index: number) => void>}

                                      //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                      //displayMore={undefined}
                                      toolbarActions={[
                                        { toolTip: `${t('Test')}...`, onClickIcon: handleExportXlsxFile ,icon: FileDownloadIcon, },  
                                        
                                    ]}
                                  />
                                </Box>
                              </Fragment>
                            )
                          })
                        }                        
                      </Stack>   
                    </Grid>    
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'  >
                        {
                          getValues().jobSchedules.map( (jobSchedule, index) => {
                            const {jobScheduleSteps} = jobSchedule;
                            return (index === jobScheduleIndex) && (
                              <Fragment key={`key-${jobSchedule.description} - ${jobScheduleIndex} - ${jobSchedule.dateType}`}>
                                <Button>
                                  {`${jobSchedule.description} : ${t('Add step')}`}
                                  <Box sx={{ ...justifyCenter, ml: 1 }}>
                                    <MdOutlineAdd size={24} onClick={handleClickAddStep} />
                                  </Box>
                                </Button>
                                <Box sx={{ mt: 1, width: '100%' }} > 
                                    <Tabs
                                      //orientation="vertical"
                                      variant="scrollable"
                                      value={valueStep}
                                      onChange={handleStepChange}
                                      aria-label="basic tabs example"
                                      
                                      //sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                      {
                                        jobScheduleSteps.map( (jobScheduleStep, idxStep) => {
                                          return (<Tab label={`Step ${idxStep+1}` } icon={<DeleteIcon onClick={ (event) => handleStepDelete(event, idxStep )} />} iconPosition="end" key={`${idxStep} - ${jobScheduleStep.id}`}  />)
                                        })
                                      }                                  
                                  </Tabs>                                  
                                </Box>
                                    {
                                        jobScheduleSteps.map( (jobScheduleStep, idxStep) => {
                                          const {type} = jobScheduleStep;
                                          return (
                                            valueStep === idxStep &&  <Stack flexDirection='column' key={`key - ${idxStep} - ${type}`} >
                                              <Box sx={{ mt: 1, width: '100%' }} >
                                                <ToggleButtonGroup size="small"
                                                  color="primary"
                                                  value={type}
                                                  exclusive
                                                  onChange={ (event, newType) => handleChangeJobType(event, newType, idxStep)}
                                                  aria-label="Small sizes" fullWidth
                                                >
                                                  <ToggleButton value="report">{t('Report')}</ToggleButton>
                                                  <ToggleButton value="feature">{t('System function')} </ToggleButton>
                                                </ToggleButtonGroup> 
                                              </Box>
                                              
                                              { type === 'feature' && <Box sx={{ mt: 1, width: '100%' }} >
                                                <Controller
                                                    key={`jobSchedules-${jobScheduleStep.id}-itemEntityQuery-x-${jobScheduleStep.featureDescription}`}
                                                    render={({ field }) => <TextField label={t('Feature')} {...field} 
                                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                          sx={{width:`calc(100% - 8px)`}}
                                                          InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment position="end">                                            
                                                                <IconButton color="primary" onClick={(event) => handleClickSearchFeature(event, idxStep)}>
                                                                  <ArrowDropDownCircleIcon />
                                                                </IconButton>                                                                                               
                                                            </InputAdornment>
                                                          )
                                                        }} />}
                                                    name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.featureDescription`}                      
                                                    control={control}
                                                  />                                                
                                                  { openFeatureFilter && <FormDialog open={openFeatureFilter} maxWidth='sm'
                                                      okText={t('OK')} cancelText='' title={t('Feature filter')} onCancel={()=> {}} 
                                                      onClose={()=> {setOpenFeatureFilter(false);}} onOk={()=> {setOpenFeatureFilter(false);}}  >
                                                          <BasicTextFilterForm<IFeatureDescription> {...basicFilterFeature } />
                                                  </FormDialog> }                                          
                                              </Box> }
                                              { type === 'report' && <Box sx={{ mt: 1, width: '100%' }} >
                                                  <Controller
                                                    key={`jobSchedules-${jobScheduleStep.id}-itemEntityQuery-x-${jobScheduleStep.reportName}`}
                                                    render={({ field }) => <TextField label={t('Report')} {...field} 
                                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                          sx={{width:`calc(70% - 8px)`}}
                                                          InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment position="end">                                            
                                                                <IconButton color="primary" onClick={(event) => handleClickSearchReport(event, idxStep)}>
                                                                  <ArrowDropDownCircleIcon />
                                                                </IconButton>                                                                                               
                                                            </InputAdornment>
                                                          )
                                                        }} />}
                                                    name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.reportName`}                      
                                                    control={control}
                                                  /> 
                                                  { openReportFilter && <FormDialog open={openReportFilter} maxWidth='md'
                                                      okText={t('OK')} cancelText='' title={t('Report filter')} onCancel={()=> {}} 
                                                      onClose={()=> {setOpenReportFilter(false);}} onOk={()=> {setOpenReportFilter(false);}}  >
                                                          <BasicTextFilterForm<IReport> {...basicFilterReport } />
                                                  </FormDialog> }   
                                                  <Controller 
                                                      name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.reportTransmissionMode`}
                                                      control={control} 
                                                      defaultValue='default'
                                                      render={ ({field: {onChange, value}}) => (
                                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="reportTransmissionMode"
                                                          label={t('Transmission mode')} inputProps={ {readOnly: false}} >
                                                          { transmissionModes.map(({name, value}, idx) => (<MenuItem key={value} value={value}>{name}</MenuItem>))}                                        
                                                        </TextField>
                                                      )}
                                                  />                                        
                                              </Box> }
                                              { type === 'report' && <Box sx={{ mt: 1, width: '100%' }} >
                                                  <Controller
                                                    key={`jobSchedules-${jobScheduleStep.id}-itemEntityQuery-x-${jobScheduleStep.reportRecipientExpression}`}
                                                    render={({ field }) => <TextField label={t('Recipient')} {...field} 
                                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                          sx={{width:`calc(100% - 8px)`}}
                                                          InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment position="end">                                            
                                                                <IconButton color="primary" onClick={(event) => handleClickOpenRecipientExpression(event, idxStep, 
                                                                                                    'reportRecipientExpression',jobScheduleStep.reportRecipientExpression )}>
                                                                  <DeveloperModeIcon />
                                                                </IconButton>                                                                                               
                                                            </InputAdornment>
                                                          )
                                                        }} />}
                                                    name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.reportRecipientExpression`}                      
                                                    control={control}
                                                  />
                                              </Box> }
                                              { (type === 'report' && currentTranmissionMode === 'email') && <Box sx={{ mt: 1, width: '100%' }} >
                                                  <Controller
                                                    key={`jobSchedules-${jobScheduleStep.id}-itemEntityQuery-x-CC${jobScheduleStep.reportRecipientExpressionCC}`}
                                                    render={({ field }) => <TextField label={`${t('Recipient')} CC`} {...field} 
                                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                          sx={{width:`calc(100% - 8px)`}}
                                                          InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment position="end">                                            
                                                                <IconButton color="primary" onClick={(event) => handleClickOpenRecipientExpression(event, idxStep, 
                                                                                                    'reportRecipientExpressionCC',jobScheduleStep.reportRecipientExpressionCC )}>
                                                                  <DeveloperModeIcon />
                                                                </IconButton>                                                                                               
                                                            </InputAdornment>
                                                          )
                                                        }} />}
                                                    name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.reportRecipientExpressionCC`}                      
                                                    control={control}
                                                  />
                                              </Box> }
                                              { (type === 'report' && currentTranmissionMode === 'email') && <Box sx={{ mt: 1, width: '100%' }} >
                                                  <Controller
                                                    key={`jobSchedules-${jobScheduleStep.id}-itemEntityQuery-x-Bcc${jobScheduleStep.reportRecipientExpressionBcc}`}
                                                    render={({ field }) => <TextField label={`${t('Recipient')} Bcc/Cci`} {...field} 
                                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                          sx={{width:`calc(100% - 8px)`}}
                                                          InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment position="end">                                            
                                                                <IconButton color="primary" onClick={(event) => handleClickOpenRecipientExpression(event, idxStep, 
                                                                                                    'reportRecipientExpressionBcc',jobScheduleStep.reportRecipientExpressionBcc )}>
                                                                  <DeveloperModeIcon />
                                                                </IconButton>                                                                                               
                                                            </InputAdornment>
                                                          )
                                                        }} />}
                                                    name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.reportRecipientExpressionBcc`}                      
                                                    control={control}
                                                  />
                                              </Box> }
                                              { type === 'report' && <Box sx={{ mt: 1, width: '100%' }} >
                                                  <Controller
                                                    key={`jobSchedules-${jobScheduleStep.id}-itemEntityQuery-x-${jobScheduleStep.reportLanguageExpression}`}
                                                    render={({ field }) => <TextField label={t('Language')} {...field} 
                                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                          sx={{width:`calc(100% - 8px)`}}
                                                          InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment position="end">                                            
                                                                <IconButton color="primary" onClick={(event) => handleClickOpenLanguageExpression(event, idxStep)}>
                                                                  <DeveloperModeIcon />
                                                                </IconButton>                                                                                               
                                                            </InputAdornment>
                                                          )
                                                        }} />}
                                                    name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.reportLanguageExpression`}                      
                                                    control={control}
                                                  />                                                                                   
                                                </Box> }
                                                <Box sx={{ mt: 1, width: '100%' }} >
                                                  <Controller name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.retentionMode`} 
                                                        control={control}                                     
                                                        render={ ({field: {onChange, value}}) => (
                                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="retentionMode"
                                                            label={t('Retention')} inputProps={ {readOnly: false}} inputRef={inputBusinessApplication}>
                                                            {enumItems.filter(x => x.enumerationCode === Enum_JOB_RETENTION_MODE).map( 
                                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                            }
                                                          </TextField>
                                                        )}
                                                    />   
                                                    { currentRetentionMode === 'PERSO' &&
                                                    <Controller
                                                      render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                                      return (
                                                        <NumberFormat              
                                                          disabled={false}
                                                          label={'#'} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                                          allowEmptyFormatting={false}
                                                          control={control}    
                                                          thousandSeparator={true}
                                                          decimalScale={0}
                                                          onValueChange={ (v) => onChange(v.floatValue) }
                                                          defaultValue={value}
                                                          value={value}

                                                          customInput={TextFieldRight}                            
                                                        />
                                                      );
                                                    }}
                                                    name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.retentionValue`} 
                                                    control={control}
                                                  /> }
                                                  { currentRetentionMode === 'PERSO' && <Controller 
                                                      name={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.retentionUnit`} 
                                                      control={control} 
                                                      //defaultValue={ (businessApplications || []).length === 1 ? (businessApplications || [])[0].id : 0 }
                                                      render={ ({field: {onChange, value}}) => (
                                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="retentionUnit"
                                                          label={t('Dur. unit')} inputProps={ {readOnly: false}} inputRef={inputBusinessApplication}>
                                                          {enumItems.filter(x => x.enumerationCode === Enum_DURATION_UNIT).map( 
                                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                          }
                                                        </TextField>
                                                      )}
                                                  /> }       
                                                </Box>
                                                { type === 'report' && <Box sx={{ mt: 1, width: '100%' }} >
                                                    <ArrayFieldTableEx<IJob,IJobScheduleStepReportTable,'id'> 
                                                          key={`Report field - ${jobScheduleIndex} - ${jobScheduleStepIndex} ${jobScheduleStep.jobScheduleStepReportTables}`}
                                                          mainObject={getValues()} fieldKey='id' 
                                                          headCells={headJobScheduleStepReportTableCells} 
                                                          rowsPathName={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${idxStep}.jobScheduleStepReportTables`}
                                                          title={t(`Items list`)} rowActionIcon={jobScheduleStepReportTableRowActionIcon}  
                                                          //onRowDoubleClick={handleAliasDoubleClick}
                                                          //onRowSelected={handleRoleEntitySelected}                                                    
                                                          refAppend={refAppendJobScheduleStepReportTables as MutableRefObject<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>}
                                                          refUpdate={refUpdateJobScheduleStepReportTable as MutableRefObject<(index: number,value: Partial<FieldArray<IJob>>) => void>}
                                                          refRemove={refRemoveJobScheduleStepReportTable as MutableRefObject<(index: number) => void>}
                                                          //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                                          toolbarActions={[
                                                              //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddReportFields ,icon: AddCircleIcon, },
                                                          ]}
                                                        />
                                                        {
                                                          openReportTableColumn && <FormDialog open={openReportTableColumn} maxWidth='sm' 
                                                            okText={t('OK')} cancelText='' title={t('Column of table (alias)')} onCancel={()=> {}} 
                                                            onClose={()=> {setOpenReportTableColumn(false);}} 
                                                            onOk={()=> {setOpenReportTableColumn(false);}} 
                                                            key={`jobScheduleStepReportTableColumns-${getValues().id}-${getValues().jobSchedules.length}`} >
                                                            <Stack flexDirection='column'>
                                                              
                                                              <Box sx={{ mt: 1, width: '100%' }} key={`jobScheduleStepReportTableColumns-${getValues().id}-${getValues().jobSchedules.length}`}>
                                                                <ArrayFieldTableEx<IJob,IJobScheduleStepReportTableColumn,'id'> 
                                                                      key={`Report field -`}
                                                                      mainObject={getValues()} fieldKey='id' 
                                                                      headCells={headReportTableColumnCells} 
                                                                      rowsPathName={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${jobScheduleStepIndex}.jobScheduleStepReportTables.${jobScheduleStepReportTableIndex}.jobScheduleStepReportTableColumns`}  
                                                                      title={t(`Items list`)} 
                                                                      rowActionIcon={jobScheduleStepReportTableColumnRowActionIcon}  
                                                                      
                                                                      //onRowSelected={handleRoleEntitySelected}                                                    
                                                                      refAppend={refAppendReportTableColumns as MutableRefObject<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>}
                                                                      refUpdate={refUpdateReportTableColumn as MutableRefObject<(index: number,value: Partial<FieldArray<IJob>>) => void>}
                                                                      refRemove={refRemoveReportTableColumn as MutableRefObject<(index: number) => void>}
                                                                      //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                                                      toolbarActions={[
                                                                        
                                                                    ]}
                                                                  />                                                                 
                                                              </Box>
                                                            </Stack>
                                                          </FormDialog>
                                                        }
                                                        { openReportTableParameter && <FormDialog open={openReportTableParameter} maxWidth='sm' 
                                                            okText={t('OK')} cancelText='' title={t('Column of table (alias)')} onCancel={()=> {}} 
                                                            onClose={()=> {setOpenReportTableParameter(false);}} 
                                                            onOk={()=> {setOpenReportTableParameter(false);}} 
                                                            key={`jobScheduleStepReportTableParameters-${getValues().id}-${getValues().jobSchedules.length}`} >
                                                            <Stack flexDirection='column'>
                                                              
                                                              <Box sx={{ mt: 1, width: '100%' }} key={`jobScheduleStepReportTableColumns-${getValues().id}-${getValues().jobSchedules.length}`}>
                                                                <ArrayFieldTableEx<IJob,IJobScheduleStepReportTableParameter,'id'> 
                                                                      key={`Report field -`}
                                                                      mainObject={getValues()} fieldKey='id' 
                                                                      headCells={headJobScheduleStepReportTableParameterCells} 
                                                                      rowsPathName={`jobSchedules.${jobScheduleIndex}.jobScheduleSteps.${jobScheduleStepIndex}.jobScheduleStepReportTables.${jobScheduleStepReportTableIndex}.jobScheduleStepReportTableParameters`}  
                                                                      title={t(`Items list`)} rowActionIcon={jobScheduleStepReportTableParameterRowActionIcon}  
                                                                      
                                                                      //onRowSelected={handleRoleEntitySelected}    refAppendJobScheduleStepReportTableParameters                                                 
                                                                      refAppend={refAppendJobScheduleStepReportTableParameters as MutableRefObject<(value: Partial<FieldArray<IJob>> | Partial<FieldArray<IJob>>[], options?: FieldArrayMethodProps) => void>}
                                                                      refUpdate={refUpdateJobScheduleStepReportTableParameter as MutableRefObject<(index: number,value: Partial<FieldArray<IJob>>) => void>}
                                                                      refRemove={refRemoveJobScheduleStepReportTableParameter as MutableRefObject<(index: number) => void>}
                                                                      //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                                                      toolbarActions={[
                                                                        
                                                                    ]}
                                                                  />                                                                 
                                                              </Box>
                                                            </Stack>
                                                          </FormDialog>
                                                        }
                                                  </Box> }
                                            </Stack>
                                            )
                                        })
                                    }
                              </Fragment>
                              )
                            })
                        }
                      </Stack>  
                    </Grid>      
                </Grid>
            </Box>
        </FormProvider> 
  )
}

