import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useEffect, useRef, useState } from 'react';
import {ICargo,   ICargoSearch } from "../models/Cargo";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';

import { addMonths, addDays } from 'date-fns';
import { currentUserSessionAtom } from 'library/store';
import { useRecoilValue } from 'recoil';

import useEnumerationService from 'features/configuration/services/Enumeration';
import { useQuery } from 'react-query';
import { Enum_OPERATION_STATUS, Enum_TICKET_RESOLUTION, IEnumerationItem } from 'features/configuration/models/Enumeration';



const _ = () => {

    const axios = useAxios();     

    const createCargo = async (cargo: ICargo)  =>       
        await (await axios.post('/api/production/cargo/create', cargo)).data;       
        
    const updateCargo = async (cargo: ICargo)  =>       
        await (await axios.post('/api/production/cargo/update', cargo)).data; 

    const attachMessageToCargo = async (request: any)  =>       
        await (await axios.post('/api/production/cargo/attach-message-to-cargo', request)).data;  
    
    const getCargo = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/cargo/get-cargo/${id}`));
      return await data;
    }

    const getCargos = async (criteria: ICargoSearch, pagination?: IPagination) : Promise<ICargo[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {startDate, endDate, reference, businessNumber, ladingNumber, containerNumber} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const {data} = (await axios.get(`/api/production/cargo/get-cargos?businessNumber=${businessNumber}&ladingNumber=${ladingNumber}&containerNumber=${containerNumber}&` + 
                      `sStartDate=${sStartDate}&sEndDate=${sEndDate}&reference=${reference}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
          
    return {    
      createCargo,
      updateCargo,

      attachMessageToCargo,

      getCargo,
      getCargos,
      
    } 
}

export default _;


export const useBasicFilterCargo = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ICargo) => void  ) => {

  const { getCargos } = _();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup, roleEntities } = useRecoilValue(currentUserSessionAtom);
  const navigate = useNavigate();

  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Cargo', 'Service'], () => 
        getEnumerationItemsByEnumerationCodes( [ Enum_TICKET_RESOLUTION, Enum_OPERATION_STATUS ] ));

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);

  const getStatus = (row: ICargo, cellId: keyof ICargo, 
    opts: {value: string|number, name: string}[]) => {
      return getAsOptions(refEnumItems.current ?? [],Enum_OPERATION_STATUS);
  }

  const [headCargoCells, setHeadCargoCells]  = useState<HeadCell<ICargo>[]>([
    {id:'reference', label : t('Reference'),  display: true, type: 'string', width: 14 },
    {id:'businessNumber', label : t('Bus. Number'),  display: true, type: 'string', width: 14 },
    {id:'containerNumber', label : t('Container num.'),  display: true, type: 'string', width: 14 },
    
    {id:'ladingNumber', label : t('Lading'),  display: true, type: 'string', width: 15 },
    {id:'shipmentReference', label : t('Shipment reference'),  display: true, type: 'string', width: 14 },
    {id:'vehicleReference', label : t('Vehicle reference'),  display: true, type: 'string', width: 14 },

    {id:'status', label : t('Status'),  display: true, type: 'string', getOptions: getStatus, width: 15 },
    
  ]); 

  const [filterElements, ] = useState<ITextFilterElement[]>([       
      
        {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
        {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},
        {name: 'reference', text: t('Reference'), value: ''}, 
        {name: 'businessNumber', text: t('Bus. Number'), value: ''}, 
        {name: 'ladingNumber', text: t('Lading'), value: ''},  
        {name: 'containerNumber', text: t('Container num.'), value: ''}, 
                
      ]);

  const [filteredCargos, ] = useState<ICargo[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<ICargo[]> => {

   
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();
            
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
    const businessNumber = filterElements.find( elt => elt.name === 'businessNumber')?.value || '';
   
    const ladingNumber = filterElements.find( elt => elt.name === 'ladingNumber')?.value || '';
    const containerNumber = filterElements.find( elt => elt.name === 'containerNumber')?.value || '';
    
    const arr = await getCargos( {startDate, endDate, reference, businessNumber, ladingNumber, containerNumber}, pagination );
    
    return arr;
  }

  const objKey: keyof ICargo = 'id';

  return {
    title: `${t('Container')} -  ${t('Palette')}`, headCells: headCargoCells, objKey,
    filterElements, rows: filteredCargos, 
    onFilterButtonClick, onRowDoubleClick
  }
}

