import { addDays, addHours, addMinutes, addMonths, addYears, getWeekOfMonth, getWeek, getDate } from "date-fns";



const _ = () => {

    const generateRandomColor = () : string => {
        const r = Math.floor(Math.random() * 256); // Random value for red (0 to 255)
        const g = Math.floor(Math.random() * 256); // Random value for green (0 to 255)
        const b = Math.floor(Math.random() * 256); // Random value for blue (0 to 255)
        return `rgb(${r},${g},${b})`; // Return the color as an RGB string
      };
        
      const capitalizeFirstLetter = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

      const range = (start: number, length: number): number[]  => 
        Array.from({ length }, (_, index) => start + index);

      const addDurationInPeriod = (date: Date ,periodicity: 'h' | 'm' | 'd' | 'M' | 'y' | 'w', dur: number) => {
        switch(periodicity) {
            case 'm': return addMinutes(date, dur);
            case 'h': return addHours(date, dur);
            case 'd': return addDays(date, dur);
            case 'w': return addDays(date, dur*7);
            case 'M': return addMonths(date, dur);
            case 'y': return addYears(date, dur);
            default: return date;
        }
      }

      const getMinNumberValue = <T>(arr: T[], prop: keyof T): number => {
        if (arr.length === 0) return 0; // handle empty array
        
        const min = arr.reduce((min, item) => (item[prop] < min ? item[prop] : min), arr[0][prop]);

        return (typeof min === 'number') ? min : 0;
      };

      const getMaxNumberValue = <T>(arr: T[], prop: keyof T): number => {
        if (arr.length === 0) return 0; // handle empty array
      
        const max = arr.reduce((max, item) => (item[prop] > max ? item[prop] : max), arr[0][prop]);
      
        return (typeof max === 'number') ? max : 0;
      };

      const toBoolean = (value: any) : boolean => value === true;

    return {
        generateRandomColor,
        capitalizeFirstLetter,
        
        addDurationInPeriod,

        range,

        getMinNumberValue,
        getMaxNumberValue,
        toBoolean
    }

}

export default _;