import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useEffect, useRef, useState } from 'react';
import {IShipment,   IShipmentSearch } from "../models/Shipment";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';

import { addMonths, addDays } from 'date-fns';
import { currentUserSessionAtom } from 'library/store';
import { useRecoilValue } from 'recoil';

import useEnumerationService from 'features/configuration/services/Enumeration';
import { useQuery } from 'react-query';
import { Enum_OPERATION_STATUS, Enum_TICKET_RESOLUTION, IEnumerationItem } from 'features/configuration/models/Enumeration';



const _ = () => {

    const axios = useAxios();     

    const createShipment = async (shipment: IShipment)  =>       
        await (await axios.post('/api/production/shipment/create', shipment)).data;       
        
    const updateShipment = async (shipment: IShipment)  =>       
        await (await axios.post('/api/production/shipment/update', shipment)).data; 

    const attachMessageToShipment = async (request: any)  =>       
        await (await axios.post('/api/production/shipment/attach-message-to-shipment', request)).data;  
    
    const getShipment = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/shipment/get-shipment/${id}`));
      return await data;
    }

    const getShipments = async (criteria: IShipmentSearch, pagination?: IPagination) : Promise<IShipment[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {startDate, endDate, reference, description, vehicleReference, vehicleName} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const {data} = (await axios.get(`/api/production/shipment/get-shipments?vehicleReference=${vehicleReference}&vehicleName=${vehicleName}&description=${description}&` + 
                      `sStartDate=${sStartDate}&sEndDate=${sEndDate}&reference=${reference}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
          
    return {    
      createShipment,
      updateShipment,

      attachMessageToShipment,

      getShipment,
      getShipments,
      
    } 
}

export default _;


export const useBasicFilterShipment = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IShipment) => void  ) => {

  const { getShipments } = _();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup, roleEntities } = useRecoilValue(currentUserSessionAtom);
  const navigate = useNavigate();

  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Shipment', 'Service'], () => 
        getEnumerationItemsByEnumerationCodes( [ Enum_TICKET_RESOLUTION, Enum_OPERATION_STATUS ] ));

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);

  const getStatus = (row: IShipment, cellId: keyof IShipment, 
    opts: {value: string|number, name: string}[]) => {
      return getAsOptions(refEnumItems.current ?? [],Enum_OPERATION_STATUS);
  }

  const [headShipmentCells, setHeadShipmentCells]  = useState<HeadCell<IShipment>[]>([
    {id:'reference', label : t('Reference'),  display: true, type: 'string', width: 14 },
    //{id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 20 },
    {id:'vehicleReference', label : t('Vehicle reference'),  display: true, type: 'string', width: 14 },
    {id:'issueDate', label : t('Date'),  display: true, type: 'date', width: 9 },

    {id:'departure', label : t('Departure'),  display: true, type: 'date', width: 9 },
    {id:'arrival', label : t('Arrival'),  display: true, type: 'date', width: 9 },

    {id:'status', label : t('Status'),  display: true, type: 'string', getOptions: getStatus, width: 14 },
    
  ]); 

  const [filterElements, ] = useState<ITextFilterElement[]>([       
      
        {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
        {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},
        {name: 'reference', text: t('Reference'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''}, 
        {name: 'vehicleReference', text: t('Vehicle reference'), value: ''}, 
        {name: 'vehicleName', text: t('Vehicle name'), value: ''},          
      ]);

  const [filteredShipments, ] = useState<IShipment[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<IShipment[]> => {
   
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();
            
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
   
    const vehicleReference = filterElements.find( elt => elt.name === 'vehicleReference')?.value || '';
    const vehicleName = filterElements.find( elt => elt.name === 'vehicleName')?.value || '';
    
    const arr = await getShipments( {startDate, endDate, reference, description, vehicleReference, vehicleName}, pagination );
    
    return arr;
  }

  const objKey: keyof IShipment = 'id';

  return {
    title: t('Shipment'), headCells: headShipmentCells, objKey,
    filterElements, rows: filteredShipments, 
    onFilterButtonClick, onRowDoubleClick
  }
}

