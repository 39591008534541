import { IExtension } from "features/configuration/models/ExtensionType";



export type ProductType = 'service' | 'article' | 'packaging' | 'formation' | 'maintenance' | 'contract' | 
        'fee' | 'rental' | 'consumption' | 'shiping' | 'laundry';

export type PackagingProductType = 'service' | 'article';

//export type RentalPeriodicityType = 'h' | 'm' | 'd' | 'w' | 'M' | 'y' ; // hour, min, day, week, Month, year

export interface IProduct {
    id: number,
    isActive: boolean,
    name: string,
    code: string,
    description: string,
    tags: string,

    sharingId: number,
    sharingName: string,

    defaultPrice: number,
    defaultMaxDiscount: number,
    defaultMaxLoaded: number,

    priceExpression: string,
    maxDiscountExpression: string,
    maxLoadedExpression: string,
    isDefaultTaxFormula: boolean,
    taxExpression: string,
    isTaxable: boolean,

    lineOfBusinessCode: string,

    customerPointExpression: string,

    familyProductName: string,
    familyProductId: number,
    familyName: string,
    publishOnWebsite: boolean,

    type: ProductType,

    serviceBeneficiaryPointExpression: string,

    serviceDurationInMinute: number,
    priceExpressionUnity: string,
    filterOption: string,
    articleWrapping: string,

    fileName: string,
    base64Picture: string,
    
    canSellWithoutStock: boolean,
    allowBillingDeliveryWithoutStock: boolean,
    reorderThreshold: number,

    quantityAvailable: number,
    quantityStock: number,
    quantityDelivery: number,
    quantityBilling: number,

    cumulativeDurationRate: number,
    durationBeforeServiceRenewalInDay: number,

    canBePublicAppointment: boolean,
    appointmentColor: string,

    maintenanceCategoryClass: string,
	  contractScopeClass: string,

    rentalPeriodicity: 'h' | 'm' | 'd' | 'w' | 'M' | 'y', //RentalPeriodicityType,

    consumptionOptionClass: string,
    isConsumptionAvailable: boolean,

    shipmentType: 'sea' | 'air',

    lineOfBusinessName: string,

    serviceTasks: IServiceTask[],
    packagingProducts: IPackagingProduct[],
    formationBillingTypes: IFormationBillingType[],

    rentalResources: IRentalResource[],

    productPrices: IProductPrice[],
    productOptions: IProductOption[],
    extensions: IExtension[],

    shipingDensityPrices: IShipingDensityPrice[],


    currentCurrencyCode: string,
    currentPriceExpression: string
  }

  export interface IProductOption {
    id: number,

    productId: number,
    code: string,
    name: string,
    description: string,
    isActive: boolean,

    fileName: string,
    base64Picture: string,

    isAvailable: boolean, // this is for check availability depends on store when billing.

    hasPicture: boolean // display to indicate if the picture is set or not.
  }

  export interface IProductPrice {
    id: number,
    type: 'product' | 'storeProduct',

    productId: number,
    storeProductId: number,

    currencyCode: string,

    defaultPrice: number,
    defaultMaxDiscount: number,
    defaultMaxLoaded: number,


    priceExpression: string,
    maxDiscountExpression: string,
    maxLoadedExpression: string,
    isDefaultTaxFormula: boolean,
    taxExpression: string,
    isTaxable: boolean,
  }

export interface IServiceTask {
  id: number,
  isActive: boolean,

  serviceId: number,
  taskCode: string,
  part: number,

  servicePart: number
}

export interface IPackagingProduct {
  id: number,
  
  packagingId: number,
  part: number,
  

  type: PackagingProductType,

  serviceId: number,
  personServiceCode: string,
  serviceBeneficiaryPointExpression: string,

  articleId: number,

  description: string,
  productName: string
}

export interface IFormationBillingType {
  id: number,
  
  formationId: number,
  billingTypeCode: string,
  dueDate: Date,
  amountPart: number,

  billingTypeName: string
}


export interface IRentalResource {
  id: number,

  rentalId: number,
  resourceId: number,

  isCapacityFixed: boolean,
  defaultCapacity: number,

  resourceName: string
}

export interface IShipingDensityPrice {
  id: number,

  productId: number,
  currencyCode: string,
  referencePrice: number,
  minDensity: number,
  maxDensity: number,
  minBillingAmount: number,
}

  
  export const defaultProduct : IProduct = {
    id: 0,
    isActive: true,
    name: '',
    code: '',
    description: '',
    tags: '',

    sharingId: 0,
    sharingName: '',

    defaultPrice: 0,
    defaultMaxDiscount: 0,
    defaultMaxLoaded: 0,


    priceExpression: '',
    maxDiscountExpression: '',
    maxLoadedExpression: '',
    isDefaultTaxFormula: true,
    taxExpression: '',
    isTaxable: true,

    lineOfBusinessCode: '',

    customerPointExpression: '',

    familyProductName: '',
    familyProductId: 0,
    familyName: '',
    publishOnWebsite: false,

    type: 'service',

    serviceBeneficiaryPointExpression: '',

    serviceDurationInMinute: 0,
    priceExpressionUnity: '',
    filterOption: '',
    articleWrapping: '',

    fileName: '',
    base64Picture: '',
    
    canSellWithoutStock: false,
    allowBillingDeliveryWithoutStock: false,
    reorderThreshold: 0,

    quantityAvailable: 0,
    quantityStock: 0,
    quantityDelivery: 0,
    quantityBilling: 0,

    cumulativeDurationRate: 100,
    durationBeforeServiceRenewalInDay: 1,

    appointmentColor: '#305BE6',
    canBePublicAppointment: false,
    
    maintenanceCategoryClass: '',
	  contractScopeClass: '',

    rentalPeriodicity: 'd',

    consumptionOptionClass: '',
    isConsumptionAvailable: false,

    shipmentType: 'sea',

    lineOfBusinessName: '',

    serviceTasks: [],
    packagingProducts: [],
    formationBillingTypes: [],

    rentalResources: [],

    productPrices: [],
    productOptions: [],
    extensions: [],

    shipingDensityPrices: [],

    currentCurrencyCode: '',
    currentPriceExpression: ''
  }


  export const defaultProductPrice : IProductPrice = {
    id: 0,
    type: 'product',

    productId: 0,
    storeProductId: 0,

    currencyCode: '',

    defaultPrice: 0,
    defaultMaxDiscount: 0,
    defaultMaxLoaded: 0,


    priceExpression: '',
    maxDiscountExpression: '',
    maxLoadedExpression: '',
    isDefaultTaxFormula: true,
    taxExpression: '',
    isTaxable: true,
  }

  export interface IStoreProduct {
    id: number,

    storeId: number,
    productId: number,

    storeName: string,
    productName: string,
    productType: ProductType,

    currentCurrencyCode: string,

    productPrices: IProductPrice[],

    storeProductOptions: IStoreProductOption[]
  }

  export interface IStoreProductOption {
    id: number,

    storeProductId: number,
    productOptionId: number,

    isAvailable: boolean,

    productOptionCode: string,
    productOptionName: string,
  }

  export const defaultStoreProduct : IStoreProduct = {
    id: 0,

    storeId: 0,
    productId: 0,

    storeName: '',
    productName: '',
    productType: 'article',

    currentCurrencyCode: '',

    productPrices: [],
    storeProductOptions: []
  }
  

  export interface IProductSearch {
 
    isActive: boolean,
    name: string,
    description: string,
    lineOfBusinessCode: string
  }

  export interface IStoreProductSearch {
     
    productName: string,
    
    storeName: string    
  }