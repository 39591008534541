
export interface ICargo {
    id: number,
    
    shipmentId: number,

    reference: string,
    
    volume: number,
    weight: number,
    
    issueDate: Date,
    description: string,
    status: string,
    statusDate: Date,
    statusPurpose: string,

    type: 'container' | 'palette',

    ladingNumber: string,
    containerNumber: string,
    businessNumber: string,
    containerLoadingDate: Date,
    customsClearanceDate: Date,

    shipmentType: 'sea' | 'air',
    shipmentStatus: string,
    shipmentDescription: string,
    shipmentReference: string,
    vehicleReference: string,

    vehicleName: string,

    shipmentDepartureLocationCode: string,
    shipmentEstimatedDeparture: Date,
    shipmentDeparture: Date,
    shipmentArrivalLocationCode: string,
    shipmentEstimatedArrival: Date,
    shipmentArrival: Date,

    cargoItems: ICargoItem[],
    currentItemIndex: number
  }

  export interface ICargoItem {
    id: number,

    cargoId: number,
    customerId: number,
    employeeId: number,
    shipingId: number,
    currencyCode: string,

    goodsNumber: string,
    reference: string,
    volume: number,
    weight: number,
    description: string,
    quantity: number,
    densityReferencePrice: number,

    customerNameFR: string,
    customerNameEN: string,

    employeeNameFR: string,
    employeeNameEN: string,

    density: number,
    lineId: number,
  }

 
  export const defaultCargo : ICargo = {
    id: 0,

    shipmentId: 0,

    reference: '',
    
    volume: 0,
    weight: 0,
    
    issueDate: new Date(),
    description: '',
    status: '95',
    statusDate: new Date(),
    statusPurpose: '',

    type: 'container',

    ladingNumber: '',
    containerNumber: '',
    businessNumber: '',
    containerLoadingDate: new Date(),
    customsClearanceDate: new Date(),

    shipmentType: 'sea',
    shipmentStatus: '95',
    shipmentDescription: '',
    shipmentReference: '',
    vehicleReference: '',
    vehicleName: '',

    shipmentDepartureLocationCode: '',
    shipmentEstimatedDeparture: new Date(),
    shipmentDeparture: new Date(),
    shipmentArrivalLocationCode: '',
    shipmentEstimatedArrival: new Date(),
    shipmentArrival: new Date(),

    cargoItems: [],

    currentItemIndex: -1
  }

  export const defaultCargoItem : ICargoItem = {
    id: 0,

    cargoId: 0,
    customerId: 0,
    employeeId: 0,
    shipingId: 0,
    currencyCode: '',

    goodsNumber: '',
    reference: '',
    volume: 0,
    weight: 0,
    description: '',
    quantity: 0,
    densityReferencePrice: 0,

    customerNameFR: '',
    customerNameEN: '',

    employeeNameFR: '',
    employeeNameEN: '',

    density: 0,
    lineId: 0,
  }

  
  export interface ICargoSearch {
    startDate: Date, 
    endDate: Date,

    reference: string,
    businessNumber: string,
    ladingNumber: string,
    containerNumber: string,
    
  }