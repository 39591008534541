import React, {FC, PropsWithChildren, useState, useEffect, useRef} from 'react';

// import library.
import { useFieldArray, useForm, useFormContext, Controller, FormProvider, FieldArrayWithId, ArrayPath, UnpackNestedValue, DeepPartial, FieldPath } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';


import NumberFormat from 'react-number-format';
// mui ...
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers/';

// import {ITarifInfo} from '../component/crm/model/BankPolicy';
import EditIcon from '@mui/icons-material/Edit';
import TextFieldRight from './TextFieldRight';
import {Button, FormControlLabel, IconButton, InputAdornment, Stack } from '@mui/material';

import useUtils from 'library/utils'
import { FormDialog } from './FormDialog';

import { stripHtml } from 'string-strip-html';
import { RichWysiwygEditor } from './RichWysiwygEditor';
import useEnumerationService, { useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';
import { IEnumerationItem } from 'features/configuration/models/Enumeration';
import { useQuery } from 'react-query';
import { isFalsy } from 'utility-types';

export type BaseType = 'string' | 'numeric' | 'boolean' | 'date' | 'time' | 'datetime';

//export type DataType = 'Base' | 'Enumeration' | 'Entity' | 'Period';

export interface IField {
  
  type: BaseType,
  dataType: 'Base' | 'Enumeration' | 'Entity' | 'Period' | 'Base64File',
  
  options?: {value: string, name: string} [],
  entities?: {id: number, name: string} [],

  enumerationCode?: string,
  updateEnumerationItems?: boolean,
  enumerationName?: string,
  enumerationDescription?: string,

  fileName?: string,
  fileAcceptedExtension?: string,
  
  isFormatedString?: boolean,
  isMultiline?: boolean,
  multiLineCount?: number,
}


export interface ArrayFieldProps<T extends IField> {    
    params: T[],

    paramsName: string,

    valueKey: keyof T,    
    labelKey: keyof T,

    dateValueKey: keyof T, 

    disabledKey?: keyof T, 

    itemsPerRow: 1 | 2 | 3
} 

 

//export function ArrayFieldBox  (props : ArrayFieldBoxProps)  {
export default function ArrayField<T extends IField> (props : ArrayFieldProps<T>)  {

  const { t, i18n } = useTranslation();

  const {getEnumerationItemsByEnumerationCodes, getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();

    
  const {params, paramsName, valueKey, labelKey, dateValueKey, disabledKey ,itemsPerRow } = props;

  const _itemsPerRow = (itemsPerRow || 1) as number;

  const w = _itemsPerRow === 3? '33' : _itemsPerRow === 2?  '50'  : '100' as string;

  const {  control, setValue, getValues  } = useFormContext();

  const {toBoolean } = useUtils();

  
  const [name, setName] = useState<string>('');
  const [label, setLabel] = useState<string>('');
  const [openFormattedStringDialog, setOpenFormattedStringDialog] = useState(false);
  const handleClickOpenFormattedString = (nam: string, lbl: string) => {   
    setName(nam);
    setLabel(lbl);
    setOpenFormattedStringDialog(true);
  }

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();


  const {data: enumItems, refetch : refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 
    params.filter(p => p.dataType === 'Enumeration' && !isFalsy(p.enumerationCode)).map(p => p.enumerationCode) ], 
      () => getEnumerationItemsByEnumerationCodes( 
        params.filter(p => p.dataType === 'Enumeration' && !isFalsy(p.enumerationCode)).map(p => String(p.enumerationCode))  ));

    const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
      
    }, [enumItems])

  function openFileDialog ( param: T ) {
    (document as any).getElementById(`file-upload-import-${param[valueKey]}`).click();        
  }

  const setFile = (_event: any, param: T, index: number) => {
    
    let f = _event.target.files![0];

    var reader = new FileReader();
    
    reader.onload = function () {
        try {
          
          var binaryString = reader.result as string;

          const base64String = binaryString
                                      .replace('data:', '')
                                      .replace(/^.+,/, '');

          
          setValue(`${paramsName}.${index}.${String(valueKey)}` as any, base64String, {shouldValidate: true});
          setValue(`${paramsName}.${index}.fileName` as any, f.name, {shouldValidate: true});   

        }catch (err) {
            console.error(err);
        }
      
    };

    reader.onerror = function () {
      console.log("File load failed");
    };

    reader.readAsDataURL(f);    
  }
  
    function displayCell(param: T, idx: number ) : React.ReactNode { 

      const disabled = disabledKey && toBoolean(param[disabledKey]);
            
      if( param.type === 'boolean')
      return (<FormControlLabel sx={{width:`calc(${w}% - 8px)`}}
              label={String(param[labelKey])}
                control={
                <Controller
                  name={`${paramsName}.${idx}.${String(valueKey)}`}
                    control={control}
                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} 
                      disabled={disabled} onChange={onChange} />}                        
                />} />);
      

      if(param.type === 'numeric' && param.dataType === 'Period')
          return (<Controller
          //key={`key-${type}-${type}`}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker //label={t('Issue date')}
              views={["year", "month"]}  
              label={String(param[labelKey])}     
              onChange={onChange}                        
              value={value}
              disabled={disabled}
              slotProps={{ textField: { sx: {width:`calc(${w}% - 8px)`}  }} }
              //renderInput={(params) => <TextField {...params} sx={{width:`calc(${w}% - 8px)`}} />}
            /> )}
            name={`${paramsName}.${idx}.${String(valueKey)}`}          
          control={control}
        />);

        if(param.type === 'numeric' && param.dataType === 'Entity')
          return (<Controller
            render={ ({field: {onChange, value}}) => (
              <TextField select onChange={onChange} value={value} sx={{width:`calc(${w}% - 8px)`}}
                 label={String(param[labelKey])} >
              {param.entities && param.entities.map( 
                (et,ix) => <MenuItem key={et.id} value={et.id}>{et.name}</MenuItem> )
              }
              </TextField>
            )}
            name={`${paramsName}.${idx}.${String(valueKey)}`} //defaultValue={param[valueKey]}
            control={control}
          />);

      if(param.type === 'numeric')
        return (<Controller
          render={({ field: {onChange, onBlur, name, value, ref} }) => {
            return (
              <NumberFormat sx={{width:`calc(${w}% - 8px)`,  style: { textAlign: 'right' }}}
                label={param[labelKey]}
                //decimalScale={2}
                allowEmptyFormatting={false}
                control={control}                          
                //fixedDecimalScale={true}              
                thousandSeparator={true}
                decimalScale={2}
                onValueChange={ (v) => onChange(v.floatValue) }
                //{...field}
                customInput={TextFieldRight}
                defaultValue={value}
                value={value}

                disabled={disabled} 
                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
              />
            );
          }}
          name={`${paramsName}.${idx}.${String(valueKey)}`} 
          control={control}
        />);

        if(param.type === 'date')
          return (<Controller
          //key={`key-${type}-${type}`}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DatePicker //label={t('Issue date')}
            disabled={disabled} 
            label={String(param[labelKey])}     
              onChange={onChange}                        
              value={value}
              slotProps={{ textField: { sx: {width:`calc(${w}% - 8px)`}  }} }
              //renderInput={(params) => <TextField {...params} sx={{width:`calc(${w}% - 8px)`}} />}
            /> )}
            name={`${paramsName}.${idx}.${String(dateValueKey)}`}          
          control={control}
        />);

        if(param.type === 'datetime')
          return (<Controller
          //key={`key-${type}-${type}`}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <DateTimePicker //label={t('Issue date')}
                
            label={String(param[labelKey])}     
              onChange={onChange}                        
              value={new Date(value)}
              slotProps={{ textField: { sx: {width:`calc(${w}% - 8px)`}  }} }
              //renderInput={(params) => <TextField {...params} sx={{width:`calc(${w}% - 8px)`}} />}
            /> )}
            name={`${paramsName}.${idx}.${String(dateValueKey)}`}          
          control={control}
        />);
       
        
        if(param.type === 'string' && param.dataType === 'Enumeration')
          return (<Controller
            render={ ({field: {onChange, value}}) => (
              <TextField select onChange={onChange} value={value} sx={{width:`calc(${w}% - 8px)`}}
                 label={String(param[labelKey])} 
                 inputProps={ {readOnly: disabled , autoComplete: 'new-password', style: {textTransform: 'none'} }} >
              {param.options && param.options.map( 
                (ei,ix) => <MenuItem key={ei.value} value={ei.value}>{ei.name}</MenuItem> )
              }
              </TextField>
            )}
            name={`${paramsName}.${idx}.${String(valueKey)}`} //defaultValue={param[valueKey]}
            control={control}
          />);

          if(param.type === 'string' && param.dataType === 'Base64File')
            return (
            <Button 
                onClick={ () => openFileDialog(param)} key={`lg - ${idx} ${getValues().params[idx].fileName} `} id={`lg ${idx} - ${getValues().params[idx].fileName}`}>
                <input type="file" id={`file-upload-import-${param[valueKey]}`} accept={param.fileAcceptedExtension} style={{ display: "none" }} onChange={ (_event) => setFile(_event, param, idx) }/>
                {isFalsy(getValues().params[idx].fileName) ? `${t('Click to toad file')} ...`: `${getValues().params[idx].fileName} -> ${t('Click to change')}`}
            </Button>);

          const isFormated = param.isFormatedString;
          return (<Controller             
            render={({ field }) => <TextField sx={{width:`calc(${w}% - 8px)`}} 
            inputProps={ { readOnly: disabled || isFormated, autoComplete: 'new-password', style: {textTransform: 'none'} } }  
            {...field} label={String(param[labelKey])} 
            InputProps={{              
              endAdornment: (
                isFormated ? <InputAdornment position="end">                                            
                  <IconButton color="primary" onClick={(event) => 
                        {handleClickOpenFormattedString(`${paramsName}.${idx}.${String(valueKey)}`, String(param[labelKey]))}}>
                    <EditIcon />
                  </IconButton>                                                                                               
              </InputAdornment> : null)
            }}   />}
            name={`${paramsName}.${idx}.${String(valueKey)}`} //defaultValue={param[valueKey]}
            control={control}            
        />);
    }

    return ( 
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} >
          <Box sx={{ mt: 1, width: '100%' }} >   
            { 
              params.map( (x,idx) => (
              (idx%_itemsPerRow===0) ? 
                (<Box key={idx} sx={{ mt: 1, width: '100%' }}>
                    {displayCell(x,idx)}
                    {(_itemsPerRow > 1 && idx+1<params.length)?displayCell(params[idx+1],idx+1):null}
                    {(_itemsPerRow > 2 && idx+2<params.length)?displayCell(params[idx+2],idx+2):null}
                 </Box>
                   ) : null)
            )}
          </Box>
          { openFormattedStringDialog && <FormDialog open={openFormattedStringDialog} maxWidth='md' 
                    okText={t('OK')} cancelText='' title={`${label} ...`} onCancel={()=> {}} 
                    onClose={()=> {setOpenFormattedStringDialog(false);}} onOk={()=> {setOpenFormattedStringDialog(false);}}  >
                    <Stack flexDirection='column'>      
                      <Box sx={{ mt: 1, width: '100%' }} > 
                        <Controller
                          render={({ field }) => 
                            <RichWysiwygEditor {...field} 
                              //mentionSuggestions={getValues().reportFields.map(({alias}) => ({text: alias, value: "${"+alias+"}"}))}
                              id={`key-${name} - ${label}`} />}
                              name={name}
                              control={control}
                              //defaultValue=""
                              
                              rules={{
                                validate: {
                                  required: (v) =>
                                    (v && stripHtml(v).result.length > 0) ||
                                    "Description is required",
                                  maxLength: (v) =>
                                    (v && stripHtml(v).result.length <= 8000) ||
                                    "Maximum character limit is 8000",
                                },
                              }}
                          />
                      </Box>
                    </Stack>
                </FormDialog> }
        </Box>);    
}

