
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import { ICargo, ICargoItem, defaultCargo, defaultCargoItem } from './models/Cargo';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useCargoService, { useBasicFilterCargo } from './services/Cargo';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService , { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import useProductService  from 'features/setup/services/Product';

import IEnumeration, { Enum_ARRIVAL_LOCATION, Enum_CURRENCY, Enum_DEPARTURE_LOCATION, Enum_OPERATION_STATUS, IEnumerationItem } from 'features/configuration/models/Enumeration';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useBasicFilterPerson } from './services/Person';
import { IPerson } from './models/Person';
import { useBasicFilterShipment } from './services/Shipment';
import { IShipment } from './models/Shipment';
import { useBasicFilterEmployee } from 'features/setup/services/Employee';
import { IEmployee } from 'features/setup/models/Employee';


import { IProduct } from 'features/setup/models/Product';
import { debounce, min } from 'lodash';
import { isFalsy } from 'utility-types';

import useUtils from 'library/utils';

export const CargoForm: FC<ICargo> = (props: ICargo = defaultCargo) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const {getShipings } = useProductService();

  const { createCargo, updateCargo } = useCargoService();

  const {getMinNumberValue, getMaxNumberValue} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterCargo = useBasicFilterCargo( 
    (event: React.MouseEvent<unknown>, row: ICargo) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
    const basicFilterPerson = useBasicFilterPerson( 
        (event: React.MouseEvent<unknown>, row: IPerson) => {
            const {id, type, firstName, lastName, birthDate, birthPlace,  birthDateType,
              particularSign, corporationName, corporationType, creationDate, creationDateType,
              portable1, portable2, email1, email2, clientOfEmployeeId, employeeFirstName, employeeLastName} = row;

            console.log(row);
            const minLineId = getMinNumberValue(getValues().cargoItems, 'lineId');

            (refAppendCargoItems.current??emptyFunc)({...defaultCargoItem, lineId: minLineId>=0 ? -1 : minLineId - 1,

                currencyCode: applicationSetup.defaultCurrencyCode,
                customerId: id,
                customerNameFR: `${lastName??''} ${firstName??''} ${corporationName??''}`.trim(),
                customerNameEN: `${firstName??''} ${lastName??''} ${corporationName??''}`.trim(),

                shipingId: (refShipings.current||[]).length === 1 ? (refShipings.current||[])[0].id : 0,
                employeeId: clientOfEmployeeId,
                employeeNameFR: `${employeeLastName??''} ${employeeFirstName??''}`.trim(),
                employeeNameEN: `${employeeFirstName??''} ${employeeLastName??''}`.trim(),
            });
                             
          setOpenPersonFilter(false);
        }
    );
  

    const [openEmployeeFilter, setOpenEmployeeFilter] = useState(false);
    const basicFilterEmployee = useBasicFilterEmployee( 
        (event: React.MouseEvent<unknown>, row: IEmployee) => {
            const {id, firstName, lastName, birthDate, birthPlace,  
              particularSign, 
              portable1, portable2, email1, email2} = row;

            // (refAppendCargoItems.current??emptyFunc)({...defaultCargoItem,
            //     customerNameFR: `${lastName} ${firstName} ${corporationName}`.trim(),
            //     customerNameEN: `${firstName} ${lastName} ${corporationName}`.trim(),
            // });
          //   alert(getValues().currentItemIndex);
          //   alert(`${lastName??''} ${firstName??''}`.trim());

          // const cargoItem = getValues().cargoItems

          setValue(`cargoItems.${getValues().currentItemIndex}.employeeId`, id, {shouldValidate: true});
          setValue(`cargoItems.${getValues().currentItemIndex}.employeeNameFR`, `${lastName??''} ${firstName??''}`.trim(), {shouldValidate: true});
          setValue(`cargoItems.${getValues().currentItemIndex}.employeeNameEN`, `${firstName??''} ${lastName??''}`.trim(), {shouldValidate: true});
                             
          setOpenEmployeeFilter(false);
        }
    );

    const [openShipmentFilter, setOpenShipmentFilter] = useState(false);
    const basicFilterShipment = useBasicFilterShipment( 
        (event: React.MouseEvent<unknown>, row: IShipment) => {
            const {id, type, status ,reference, description, vehicleName, vehicleReference, 
              departureLocationCode, estimatedDeparture, departure, arrivalLocationCode, estimatedArrival, arrival
            } = row;

          setValue('shipmentId', id);
          setValue('shipmentType', type);
          setValue('shipmentStatus', status);

          setValue('shipmentReference', reference);
          setValue('shipmentDescription', description);

          setValue('vehicleName', vehicleName);
          setValue('vehicleReference', vehicleReference);

          setValue('shipmentDepartureLocationCode', departureLocationCode);
          setValue('shipmentEstimatedDeparture', estimatedDeparture);
          setValue('shipmentDeparture', departure);

          setValue('shipmentArrivalLocationCode', arrivalLocationCode);
          setValue('shipmentEstimatedArrival', estimatedArrival);
          setValue('shipmentArrival', arrival);

          setValue('type', type === 'sea'? 'container' : 'palette');
                             
          setOpenShipmentFilter(false);
        }
    );
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<ICargo>({defaultValues:defaultCargo});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchType = watch('type');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ICargo>,Error,ICargo>(
      _id>0?updateCargo:createCargo, {   
        onSuccess: (data: IResult<ICargo>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Container')} -  ${t('Palette')} - # ${data.data.id} # ` );
          
          //queryClient.invalidateQueries(['Cargo',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ICargo>(['Cargo', _id], () => retrieveEntity('Cargo',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Cargo'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_OPERATION_STATUS, Enum_DEPARTURE_LOCATION, Enum_ARRIVAL_LOCATION, Enum_CURRENCY ] ));

    const {data: shipings} = useQuery<IProduct[]>( ['Shipings', 'Type', getValues().shipmentType], () => getShipings
        ( getValues().shipmentType, '', '', '' ));


        const getInputAdornmentItemEmployee = (row: ICargoItem, cellId: keyof ICargoItem)  => ({
 
          toolTip: 'Details',
          icon: row.employeeId >= 0 ? RemoveCircleIcon : ArrowDropDownCircleIcon,
          onClickIcon: (event: any, index: number, row: ICargoItem ) => {
            
            if(row.employeeId <= 0) {
              setValue('currentItemIndex', index);
              setOpenEmployeeFilter(true);
            } else {
              const cargoItem = getValues().cargoItems[index];
              (refUpdateCargoItem.current??emptyFunc)(index, {...cargoItem, employeeId: 0, employeeNameEN: '', employeeNameFR: '' });
            }            
          }  
       })

       const [openCargoItemMore, setOpenCargoItemMore] = useState<boolean>(false);
    //    const getInputAdornmentItemMore = (row: ICargoItem, cellId: keyof ICargoItem)  => ({
 
    //     toolTip: 'Details',
    //     icon: MoreIcon,
    //     onClickIcon: (event: any, index: number, row: ICargoItem ) => {
          
    //       setValue('currentItemIndex', index);
    //       setOpenCargoItemMore(true);
    //     }  
    //  })

       const getShipingsAsOptions = (row: ICargoItem, cellId: keyof ICargoItem, 
         opts: {value: string|number, name: string}[]) : {value: string|number, name: string}[]=> {        
         
           return (refShipings.current||[]).map(x => ({value: x.id, name: x.name}));
       }
       
       
        const cellEditableCargoItem = (row: ICargoItem, cellId: keyof ICargoItem) => {      
          return true;
        }

        const cellAllowedVolume = (row: ICargoItem, valueAmount: number) => {
          const {volume, weight, } = row;
                  
          const allowed = true;
          debouncedDensity(row);
    
          return allowed;
        }

        const cellAllowedWeight = (row: ICargoItem, valueAmount: number) => {
          const {volume, weight, } = row;
                  
          const allowed = true;
          debouncedDensity(row);
    
          return allowed;
        }

        const debouncedDensity = useRef(
          debounce( (row: ICargoItem) => {

            const shiping = (refShipings.current||[]).find(s => s.id === row.shipingId);
            
            if(isFalsy(shiping)) return;
            const {shipingDensityPrices} = shiping;

            const index = getValues().cargoItems.findIndex(x => x.lineId === row.lineId);
            if(index < 0) return;
            const cargoItem = getValues().cargoItems[index];

            const {volume, weight} = cargoItem;
            
            const densityCalc = volume === 0 ? weight * 1000 : weight / volume;
            const shipingDensityPrice = shipingDensityPrices.find(x => x.minDensity<= densityCalc && densityCalc <= x.maxDensity);

            (refUpdateCargoItem.current??emptyFunc)(index, {...cargoItem, density: densityCalc,
                densityReferencePrice: shipingDensityPrice?.referencePrice?? 
                         (densityCalc === 0 ? getMinNumberValue(shipingDensityPrices,'referencePrice'):
                                          getMaxNumberValue(shipingDensityPrices,'referencePrice'))
          });
            //const sumAmount = sum( getValues().tellerOperationBillings.map( ({amount}) => amount ) );
            //setValue('amount', sumAmount);
          }, 1300)
        ).current;
      
      const [headCargoItemCells, setHeadCargoItemCells]  = useState<HeadCell<ICargoItem>[]>([]);
      useEffect(() => {
        setHeadCargoItemCells([   
          {id:'customerNameFR', label : t('Customer'),  display: lg.includes('fr'), type: 'string', width: 20}, 
          {id:'customerNameEN', label : t('Customer'),  display: lg.includes('en'), type: 'string', width: 20}, 

          {id:'employeeNameFR', label : t('Employee'),  display: lg.includes('fr'), type: 'string', width: 15, 
             getInputAdornment: getInputAdornmentItemEmployee}, 
          {id:'employeeNameEN', label : t('Employee'),  display: lg.includes('en'), type: 'string', width: 15, 
             getInputAdornment: getInputAdornmentItemEmployee}, 

          {id:'shipingId', label : t('Product'),  display: true, type: 'string', width: 15, 
              isEditable: cellEditableCargoItem, getOptions: getShipingsAsOptions}, 


          //{id:'reference', label : t('Reference'),  display: true, type: 'string', width: 9, isEditable: cellEditableCargoItem},   
          {id:'goodsNumber', label : t('Number'),  display: true, type: 'string', width: 9,}, 
            
                        
          {id:'quantity', label : `# ${t('_Colis')}`,  display: true, type: 'numeric', decimalScale:0, width: 5, 
            isEditable: cellEditableCargoItem }, 
              //{id:'description', label : t('Description'),  display: true, type: 'string', width: 25, isEditable: cellEditableCargo}, 
          {id:'volume', label : t('Volume'),  display: true, type: 'numeric', decimalScale:5, width: 9, 
              isEditable: cellEditableCargoItem, isAllowed: cellAllowedVolume}, 
          {id:'weight', label : t('Weight'),  display: true, type: 'numeric', decimalScale:5, width: 9, 
              isEditable: cellEditableCargoItem, isAllowed: cellAllowedWeight}, 
          {id:'density', label : t('Density'),  display: true, type: 'numeric', decimalScale:4, width: 9, }, 
          
          {id:'densityReferencePrice', label : t('Ref. price'),  display: true, type: 'numeric', decimalScale:2, width: 9, }, 

          
          
          //{id:'status', label : t('Status'),  display: true, type: 'string', width: 10, isEditable: cellEditableCargoItem, getOptions: getStatusCode}
        ]);
      }, [t,i18n]);
            
      
      const refAppendCargoItems = useRef<(value: Partial<FieldArray<ICargo>> | Partial<FieldArray<ICargo>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateCargoItem = useRef<(index: number,value: Partial<FieldArray<ICargo>> ) => void>(null);
      const refRemoveCargoItem = useRef<(index: number ) => void>(null);
      
      const cargoItemRowActionIcon = ( row: ICargoItem) : ActionIconTableRow<ICargo,ICargoItem> => {
              
        const res: ActionIconTableRow<ICargo,ICargoItem> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ICargoItem) => {
            
            //(refRemoveCargoItem.current??emptyFunc)(index);                        
          }
        }
        return res;
    }

    const cargoItemMenuRowActionIcon = (row: ICargoItem) => ({
      icon: MoreVertIcon,
      rowActionIcons: [
        {
          toolTip: 'View details',
          icon: MoreIcon,
          hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ICargoItem) => {
            
            setValue('currentItemIndex', index);
            setOpenCargoItemMore(true);                        
          }
        },
        {
          toolTip: 'Remove',
          icon: RemoveCircleIcon,
          hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ICargoItem) => {
            alert(index);
            (refRemoveCargoItem.current??emptyFunc)(index);                        
          }
        },
        {
          toolTip: 'Operation on',
          icon: SystemUpdateAltIcon,
          hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ICargoItem) => {
            
            if( getValues().currentItemIndex < 0 || getValues().currentItemIndex >= getValues().cargoItems.length) {
              return;
             }
   
            const cargoItem = getValues().cargoItems[getValues().currentItemIndex];
            if(cargoItem.id <= 0) {
              return;
            }

             setOpenCargoItemMore(false);
            openEntityActionDrawer('CargoItem', cargoItem.id);                      
          }
        }
      ]      
    })
      
      const handleAddCargoItems = (event: any) => {
        setOpenPersonFilter(true);
      }


      const handleClickOpenShipment = (event: any) => {      
        setOpenShipmentFilter(true);      
      }

      const handleClickOpenPerson = (event: any) => {      
        //setCurrentTeacherUsage(teacherUsage);
        setOpenPersonFilter(true);      
      }
      
      const refShipings = useRef<IProduct[]>();    
          useEffect( () => {   
            refShipings.current = shipings;
            
          }, [shipings])
      
      useEffect( () => {        
        setCurrentFormNameAtom(`${t('Container')} -  ${t('Palette')}`);
        setCurrentBasicTextFilterProps(basicFilterCargo);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Cargo',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);

            setCurrentFormNameAtom(`${t('Container')} -  ${t('Palette')} - # ${_data.id} # `);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultCargo);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('Cargo', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.reference.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }

            if(data.shipmentId <=0 ) {
              enqueueSnackbar( t('Shipment is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }

            const invalidItems = data.cargoItems.filter(x => x.customerId <= 0);
            if(invalidItems.length !== 0) {
              enqueueSnackbar( `${t('Invalid data')} : ${t('Customer')}`, 
                  { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }

            const emptyItems = data.cargoItems.filter(x => x.volume*x.weight*x.quantity === 0);
            if(emptyItems.length !== 0) {
              enqueueSnackbar( `${t('There is element in packing list with empty value')} : ${emptyItems[0].customerNameFR}-${emptyItems[0].reference}`, 
                  { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Cargo', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['Cargo',_id]);        
      await retrieveData('Cargo',_id, refetch);        
      reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Identification of shipment'))} -/-`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                <Controller name='shipmentType' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="shipmentType"
                                      label={t('Type')} inputProps={ {readOnly: true}}>
                                      <MenuItem key='sea' value='sea'>{t('Maritime')}</MenuItem>
                                      <MenuItem key='air' value='air'>{t('Air_')}</MenuItem> 
                                    </TextField>
                                    )}
                                  />
                                <TextField sx={{width:'calc(25% - 8px)'}} id="shipmentReference" label={t('Reference')} 
                                  {...register('shipmentReference')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenShipment}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>) 
                                  }} />
                                <TextField sx={{width:'calc(25% - 8px)'}} id="shipmentDescription" label={t('Description')} 
                                  {...register('shipmentDescription')} />
                                
                                { openShipmentFilter && <FormDialog open={openShipmentFilter} maxWidth='md'
                                      okText='' cancelText='' title={t('Shipment')} onCancel={()=> {}} 
                                      onClose={()=> {setOpenShipmentFilter(false);}} onOk={()=> {setOpenShipmentFilter(false);}}  >
                                          <BasicTextFilterForm<IShipment> {...basicFilterShipment } />
                                  </FormDialog> }
                            </Box>
                            {/* <Box sx={{ mt: 0.25, width: '100%' }} >
                              
                              <Controller name='shipmentStatus' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="shipmentStatus"
                                        label={t('Status')} inputProps={ {readOnly: true}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box>                             */}
                            {/* <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="reference" label={t('Vehicle name')} 
                                    {...register('vehicleName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="description" label={t('Vehicle reference')} 
                                {...register('vehicleReference')} />
                            </Box> */}
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='shipmentDepartureLocationCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="departureLocation"
                                      label={t('Departure location')} inputProps={ {readOnly: true}}>
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_DEPARTURE_LOCATION ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='shipmentEstimatedDeparture' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Estimated departure')} 
                                      onChange={onChange} disableOpenPicker disabled                 
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    /> )}
                                />
                                {/* <Controller control={control}
                                  name='shipmentDeparture' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Departure')} 
                                      onChange={onChange} disableOpenPicker disabled                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(13% - 8px)'}  }} }
                                    /> )}
                                /> */}
                            
                              <Controller name='shipmentArrivalLocationCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="arrivalLocation"
                                      label={t('Arrival location')} inputProps={ {readOnly: true}}>
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_ARRIVAL_LOCATION ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='shipmentEstimatedArrival' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Estimated arrival')} 
                                      onChange={onChange} disableOpenPicker disabled                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    /> )}
                                />
                                {/* <Controller control={control}
                                  name='shipmentArrival' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Arrival')} 
                                      onChange={onChange}  disableOpenPicker disabled                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(13% - 8px)'}  }} }
                                    /> )}
                                /> */}
                            </Box>
                            
                            
                            
                            
                            
                            
                            {/* <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='status' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(60% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: true}}>
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange} disableOpenPicker disabled                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    /> )}
                                />
                                <Controller control={control}
                                  name='issueDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Issue date')} 
                                      onChange={onChange}  disableOpenPicker disabled                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    /> )}
                                />
                            </Box> */}
                        </Stack>                        
                    </Grid> 
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Identification of'))} ${t('container')}`}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(25% - 8px)'}} id="reference" label={t('Reference')} 
                                  {...register('reference')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            <TextField sx={{width:'calc(25% - 8px)'}} id="businessNumber" label={t('Bus. Number')} 
                              {...register('businessNumber')} />
                            <TextField sx={{width:'calc(25% - 8px)'}} id="containerNumber" label={t('Container num.')} 
                                  {...register('containerNumber')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            <TextField sx={{width:'calc(25% - 8px)'}} id="ladingNumber" label={t('Lading')} 
                              {...register('ladingNumber')} />
                          </Box>
                          <Box sx={{ mt: 0.25, width: '100%' }} >
                              <TextField sx={{width:'calc(35% - 8px)'}} id="description" label={t('Description')} 
                                    {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <Controller control={control}
                                  name='containerLoadingDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Loading')} 
                                      onChange={onChange}                  
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    /> )}
                                />
                                <Controller control={control}
                                  name='customsClearanceDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Customs clear.')} 
                                      onChange={onChange}                      
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    /> )}
                                />
                                <Controller name='status' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="status"
                                          label={t('Status')} inputProps={ {readOnly: true}} >
                                          {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                              {/* <Controller                                    
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat sx={{width:'calc(10% - 8px)'}}     
                                        label={t('Volume')}
                                        decimalScale={5}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                        
                                        thousandSeparator={true}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                      />
                                    );
                                  }}
                                  name='volume'                                  
                                  control={control}
                                /> 
                                <Controller                                    
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat sx={{width:'calc(10% - 8px)'}}     
                                        label={t('Weight')}
                                        decimalScale={5}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                        
                                        thousandSeparator={true}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                      />
                                    );
                                  }}
                                  name='weight'                                  
                                  control={control}
                                /> */}
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'>
                          <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} 
                            key={getValues().cargoItems.map(x => `${x.employeeId}-${x.employeeNameEN}`).join('*')}
                          >
                            <ArrayFieldTableEx<ICargo,ICargoItem,'id'> 
                              key={getValues().cargoItems.map(x => `${x.employeeId}-${x.employeeNameEN}`).join('*')}
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headCargoItemCells} rowsPathName={`cargoItems`}
                              title={t('Packing list')} 
                              rowMenuActionIcon={cargoItemMenuRowActionIcon}  
                              //onRowSelected={handleBillingDetailSelected}
                              //onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                  
                              refAppend={refAppendCargoItems as MutableRefObject<(value: Partial<FieldArray<ICargo>> | Partial<FieldArray<ICargo>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateCargoItem as MutableRefObject<(index: number,value: Partial<FieldArray<ICargo>>) => void>}
                              refRemove={refRemoveCargoItem as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={                                
                                [
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddCargoItems ,icon: AddCircleIcon }, 
                                ]
                              } 
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                            { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                      okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                      onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                          <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                                  </FormDialog> }
                            { openEmployeeFilter && <FormDialog open={openEmployeeFilter} maxWidth='md'
                                      okText='' cancelText='' title={t('Employee')} onCancel={()=> {}} 
                                      onClose={()=> {setOpenEmployeeFilter(false);}} onOk={()=> {setOpenEmployeeFilter(false);}}  >
                                          <BasicTextFilterForm<IEmployee> {...basicFilterEmployee } />
                                  </FormDialog> }
                            { openCargoItemMore && <FormDialog open={openCargoItemMore} maxWidth='sm' height='70vh'
                                      okText={''} cancelText={t('Close')} title={t('Shipment')} 
                                      onCancel={()=> {setOpenCargoItemMore(false);}} 
                                      onClose={()=> {setOpenCargoItemMore(false);}} onOk={()=> {setOpenCargoItemMore(false);}}  >
                                    <Stack flexDirection='column'>
                                      <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} 
                                        key={`cargoItems.${getValues().currentItemIndex}.currencyCode`}>
                                        <Controller 
                                          name={`cargoItems.${getValues().currentItemIndex}.currencyCode`} 
                                          control={control}                                     
                                            render={ ({field: {onChange, value}}) => (
                                              <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="currencyCode"
                                                label={t('Currency')} inputProps={ {readOnly: false}}>
                                                {enumItems && enumItems.filter( e => 
                                                      e.enumerationCode === Enum_CURRENCY ).map( 
                                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                                }
                                              </TextField>
                                            )}
                                        />
                                      </Box>
                                      <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} 
                                        key={`cargoItems.${getValues().currentItemIndex}.goodsNumber`}>
                                        <Controller name={`cargoItems.${getValues().currentItemIndex}.goodsNumber`} control={control}   
                                            render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                            label={`${t('Number')} (Tracking)`}  inputProps={ {readOnly: true}} />}                                  
                                        />
                                      </Box>
                                      <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} 
                                        key={`cargoItems.${getValues().currentItemIndex}.reference`}>
                                        <Controller name={`cargoItems.${getValues().currentItemIndex}.reference`} control={control}   
                                            render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                            label={`${t('Number')} (Tracking)`}  inputProps={ {readOnly: true}} />}                                  
                                        />
                                      </Box>
                                      <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} 
                                        key={`cargoItems.${getValues().currentItemIndex}.description`}>
                                        <Controller name={`cargoItems.${getValues().currentItemIndex}.description`} control={control}   
                                            render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                            multiline rows={3}
                                            label={`${t('Description')}`}  inputProps={ {readOnly: false}} />}                                  
                                        />
                                      </Box>
                                    </Stack>
                                  </FormDialog> }

                          </Box>
                        </Stack>                   
                    </Grid>
                </Grid>
            </Box>
        </FormProvider> 
  )
}

