
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IProduct, IProductOption, IProductSearch, IServiceTask, IStoreProduct, IStoreProductSearch } from "../models/Product";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/production/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createProduct = async (product: IProduct)  =>       
        await (await axios.post('/api/setup/product/create', product)).data;  
    
    const createBasicImportProduct = async (product: IProduct[])  =>       
      await (await axios.post('/api/setup/product/create-basic-import', product)).data;  
        
    const updateProduct = async (product: IProduct)  =>       
        await (await axios.post('/api/setup/product/update', product)).data; 
    
    const getProduct = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/product/get-product/${id}`));
      return await data;
    }
   

    const getProducts = async (type: number, criteria: IProductSearch, pagination?: IPagination) : Promise<IProduct[]> => {

      const {isActive, name, description, lineOfBusinessCode} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/product/get-products?type=${type}&isActive=${isActive}&name=${name}&description=${description}&lineOfBusinessCode=${lineOfBusinessCode}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getShipings = async (shipmentType: string, name: string,description: string,lineOfBusinessCode: string ) : Promise<IProduct[]> => {

      const {data} = (await axios.get(`/api/setup/product/get-shipings?shipmentType=${shipmentType}&name=${name}&description=${description}&lineOfBusinessCode=${lineOfBusinessCode}`));
      return await data;
    }

    const getProductsWithOptions = async (type: number,storeId: number, currencyCode: string, criteria: IProductSearch, pagination?: IPagination) : Promise<IProduct[]> => {

      const {isActive, name, description, lineOfBusinessCode} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/product/get-products-with-options?type=${type}&isActive=${isActive}&name=${name}&description=${description}&lineOfBusinessCode=${lineOfBusinessCode}&storeId=${storeId}&currencyCode=${currencyCode}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getProductsSearchCount = async (name: string, description: string) : Promise<number> => {
      
      const {data} = (await axios.get(`/api/setup/product/get-products-search-count?name=${name}&description=${description}`));
      return await data;
    }

    const getProductsSearch = async (name: string, description: string, criteria: IProductSearch, pagination?: IPagination) : Promise<IProduct[]> => {

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/product/get-products-search?name=${name}&description=${description}`+
                  `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
    
    const getProductsByFamilyProduct = async (familyProductId: number) : Promise<IProduct[]> => {
     
      const {data} = (await axios.get(`/api/setup/product/get-products-by-familyProduct?familyProductId=${familyProductId}`));
      return await data;
    }

    const getServiceTasksByService = async (serviceId: number) : Promise<IServiceTask[]> => {

      const {data} = (await axios.get(`/api/setup/product/get-serviceTasks-by-service?serviceId=${serviceId}`));
      return await data;
    }
    
    const getServiceTasksByPackaging = async (packagingId: number) : Promise<IServiceTask[]> => {

      const {data} = (await axios.get(`/api/setup/product/get-serviceTasks-by-packaging?packagingId=${packagingId}`));
      return await data;
    }

    const saveStoreProduct = async (storeProduct: IStoreProduct) : Promise<IResult<IStoreProduct>>  =>       
      await (await axios.post('/api/setup/product/save-store-product', storeProduct)).data; 

    const getStoreProducts = async (criteria: IStoreProductSearch, pagination?: IPagination) : Promise<IStoreProduct[]> => {

      const {productName, storeName} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/product/get-store-products?productName=${productName}&storeName=${storeName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getStoreProduct = async (storeId: number, productId: number) : Promise<IStoreProduct> => {

      const {data} = (await axios.get(`/api/setup/product/get-store-product?storeId=${storeId}&productId=${productId}`));
      return await data;
    }

    const getProductOptionsByBilling = async (billingId: number) : Promise<IProductOption[]> => {

      const {data} = (await axios.get(`/api/setup/product/get-product-options-by-billing?billingId=${billingId}`));
      return await data;
    }
   
    return {    
      createProduct,
      createBasicImportProduct,
      updateProduct,
      getProduct,
      getProducts,
      getShipings,
      getProductsWithOptions,

      saveStoreProduct,
      getStoreProducts,
      getStoreProduct,

      getProductOptionsByBilling,

      getProductsSearchCount,
      getProductsSearch,
      getProductsByFamilyProduct,

      getServiceTasksByService,
      getServiceTasksByPackaging
    } 
}

export default _;

export interface IFilterProductOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IProduct[], React.Dispatch<React.SetStateAction<IProduct[]>>],
}

const defaultFilterProductOption: IFilterProductOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterProduct = ( type: number,  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IProduct) => void,
                                            filterOption?: IFilterProductOption  ) => {

  const { getProducts } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headProductCells, setHeadProductCells]  = useState<HeadCell<IProduct>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'lineOfBusinessName', label : t('Line of business'),  display: true, type: 'string', },

    {id: 'quantityAvailable', label : `${t('Quantity')} : ${t('Available')}`,  display: true && (type === 2), type: 'numeric',},
    {id: 'quantityStock', label : `${t('Quantity')} : ${t('Stock')}`,  display: true && (type === 2), type: 'numeric',}
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'isActive', text: t('Active ?'), dataType: 'boolean' ,value: '', boolValue: true},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'lineOfBusinessCode', text: t('Line of business'), value: ''},
    ]);    

  const [filteredProducts, ] = useState<IProduct[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IProduct[]> => {
   
    
    const isActive = filterElements.find( elt => elt.name === 'isActive')?.boolValue ?? true;

    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const lineOfBusinessCode = filterElements.find( elt => elt.name === 'lineOfBusinessCode')?.value || '';

    const arr = await getProducts(type, {isActive, name,description, lineOfBusinessCode}, pagination );
    
    return arr;
  }

  const objKey: keyof IProduct = 'id';

  return {
    title: t('Product'), headCells: headProductCells, objKey,
    filterElements, rows: filteredProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterProductWithOptions = ( type: number, storeId: number, currencyCode: string, onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IProduct) => void,
                                            filterOption?: IFilterProductOption  ) => {

  const { getProductsWithOptions } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headProductCells, setHeadProductCells]  = useState<HeadCell<IProduct>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'lineOfBusinessName', label : t('Line of business'),  display: true, type: 'string', },

    {id: 'quantityAvailable', label : `${t('Quantity')} : ${t('Available')}`,  display: true && (type === 2), type: 'numeric',},
    {id: 'quantityStock', label : `${t('Quantity')} : ${t('Stock')}`,  display: true && (type === 2), type: 'numeric',}
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'isActive', text: t('Active ?'), dataType: 'boolean' ,value: '', boolValue: true},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'lineOfBusinessCode', text: t('Line of business'), value: ''},
    ]);    

  const [filteredProducts, ] = useState<IProduct[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IProduct[]> => {
   
    
    const isActive = filterElements.find( elt => elt.name === 'isActive')?.boolValue ?? true;

    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const lineOfBusinessCode = filterElements.find( elt => elt.name === 'lineOfBusinessCode')?.value || '';

    const arr = await getProductsWithOptions(type, storeId, currencyCode, {isActive, name,description, lineOfBusinessCode}, pagination );
    
    return arr;
  }

  const objKey: keyof IProduct = 'id';

  return {
    title: t('Product'), headCells: headProductCells, objKey,
    filterElements, rows: filteredProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}



export const useBasicFilterProductSearch = ( getSearch: () => {name: string, description: string}, 
                                            onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IProduct) => void,
                                            filterOption?: IFilterProductOption  ) => {

  const { getProductsSearch } = _();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headProductCells, setHeadProductCells]  = useState<HeadCell<IProduct>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'lineOfBusinessName', label : t('Line of business'),  display: true, type: 'string', },
  ]); 

  const {name, description} = getSearch();

  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
    {name: 'portable', text: t('Portable'), value: ''},
    {name: 'email', text: t('Email'), value: ''},
      
  ]);    

  const [filteredProducts, ] = useState<IProduct[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IProduct[]> => {    
      
    const lineOfBusinessCode = filterElements.find( elt => elt.name === 'lineOfBusinessCode')?.value || '';     

    const arr = await getProductsSearch(name, description, { isActive: true, name: '', description: '', lineOfBusinessCode}, pagination );
   
    return arr;
  }

  const objKey: keyof IProduct = 'id';

  return {
    title: `${t('Product')} : ${name} | ${description} `, headCells: headProductCells, objKey,
    filterElements, rows: filteredProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered,
    autoFilter: true
  }
}

////// Store Product ....
export interface IFilterStoreProductOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IProduct[], React.Dispatch<React.SetStateAction<IProduct[]>>],
}

const defaultFilterStoreProductOption: IFilterStoreProductOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterStoreProduct = ( type: number,  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IStoreProduct) => void,
                                            filterOption?: IFilterStoreProductOption  ) => {

  const { getStoreProducts } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterStoreProductOption;


  const [headStoreProductCells, setHeadStoreProductCells]  = useState<HeadCell<IStoreProduct>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'productName', label : t('Name'),  display: true, type: 'string', },
    {id:'storeName', label : t('Description'),  display: true, type: 'string', },
    
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      
      {name: 'productName', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'lineOfBusinessCode', text: t('Line of business'), value: ''},
    ]);    

  const [filteredProducts, ] = useState<IStoreProduct[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IStoreProduct[]> => {
   
    
    const productName = filterElements.find( elt => elt.name === 'productName')?.value || '';

    const storeName = filterElements.find( elt => elt.name === 'storeName')?.value || '';
    
    const arr = await getStoreProducts({productName, storeName}, pagination );
    
    return arr;
  }

  const objKey: keyof IStoreProduct = 'id';

  return {
    title: t('Product of store'), headCells: headStoreProductCells, objKey,
    filterElements, rows: filteredProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

