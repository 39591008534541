
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {get} from 'lodash';

import { Controller, FieldArray, FieldArrayMethodProps, FieldPath, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import TokenIcon from '@mui/icons-material/Token';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { IApplicationSetup, defaultApplicationSetup } from './models/ApplicationSetup';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useApplicationSetupService, { useBasicFilterApplicationSetup } from './services/ApplicationSetup';
import useApplicationQueryService, {useBasicFilterApplicationQuery} from './services/ApplicationQuery';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import {  IconButton, InputAdornment, MenuItem, Popover, Typography, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';

import useEnumerationService from 'features/configuration/services/Enumeration';

import IEnumeration, { IEnumerationItem, Enum_APPOINTMENT_NOTIFICATION_MODE, 
    Enum_JOB_RETENTION_MODE, Enum_DEFAULT_LANGUAGE,
    Enum_CURRENCY,
    Enum_TICKET_RESOLUTION,
    Enum_PAYMENT_SOURCE} from 'features/configuration/models/Enumeration';


import { defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import md5 from 'md5';
import { IAppTheme, defaultAppTheme, IAppLogo, defaultAppLogo, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';
import { IBusinessApplication, IApplicationQuery } from './models/ApplicationQuery';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { isFalsy } from 'utility-types';
import { ChromePicker, ColorResult } from 'react-color';
import { IReport } from 'features/configuration/models/Report';
import { useBasicFilterReport } from 'features/configuration/services/Report';

type QueryType = 'intermediary' | 'policy' | 'policyRisk' | 'policyGet' | 'person';

export const ApplicationSetupForm: FC<IApplicationSetup> = (props: IApplicationSetup = defaultApplicationSetup) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 999 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { createApplicationSetup, updateApplicationSetup, getApplicationTheme, getApplicationLogo,
      defineApplicationTheme, defineApplicationLogo } = useApplicationSetupService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();
  //const {getBusinessApplications} = useApplicationQueryService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg} = useRecoilValue(currentUserSessionAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterApplicationSetup = useBasicFilterApplicationSetup( 
    (event: React.MouseEvent<unknown>, row: IApplicationSetup) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openReportFilter, setOpenReportFilter] = useState(false);
    const basicFilterReport = useBasicFilterReport( 
        async (event: React.MouseEvent<unknown>, row: IReport) => {
            if( isFalsy(row)) return;
            const {id, name, entityName ,description} = row;

            if(reportUsage === 'appointment-validation') {
              if(entityName !== 'Appointment') {
                enqueueSnackbar( t('The report selected is not compatible with the entity'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
                return;
              }

              setValue('appointmentValidationNotificationReportId', id);
              setValue('appointmentValidationNotificationReportName', name);
            } 
                
            setOpenReportFilter(false);
        }
    );

  const refCurrentBusinessApplicationId = useRef(0);

  const [expressionField, setExpressionField] = useState<keyof IApplicationSetup>('certificateDocumentExpression');      

  
  const methods = useForm<IApplicationSetup>({defaultValues:defaultApplicationSetup});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;


  const { register: registerTheme, setValue: setValueTheme ,getValues: getValuesTheme, watch: watchTheme, 
          reset: resetTheme ,control: controlTheme , formState: { errors: errorsTheme } } = 
                useForm<IAppTheme>({defaultValues:defaultAppTheme});

  const { register: registerLogo, setValue: setValueLogo ,getValues: getValuesLogo, watch: watchLogo, 
    reset: resetLogo ,control: controlLogo , formState: { errors: errorsLogo } } = 
          useForm<IAppLogo>({defaultValues:defaultAppLogo});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const watchBase64Logo = watchLogo('base64Logo');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IApplicationSetup>,Error,IApplicationSetup>(
      _id>0?updateApplicationSetup:createApplicationSetup, {   
        onSuccess: (data: IResult<IApplicationSetup>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
                setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ApplicationSetup',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

  const {isLoading: isLoadingTheme, isError: isErrorTheme, isSuccess:isSuccessTheme, error: errorTheme, 
    mutate: mutateAppplicationTheme } = useMutation<IResult<boolean>,Error,IAppTheme>(
      defineApplicationTheme, {   
        onSuccess: (data: IResult<boolean>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
        
          //_setId(data.data.id);
          setOpenApplicationTheme(false);    
        },
        onError: (err: Error) => {          
          
          enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
              setOpenApplicationTheme(false);
        }
      });

      const {isLoading: isLoadingLogo, isError: isErrorLogo, isSuccess:isSuccessLogo, error: errorLogo, 
        mutate: mutateAppplicationLogo } = useMutation<IResult<boolean>,Error,IAppLogo>(
          defineApplicationLogo, {   
            onSuccess: (data: IResult<boolean>) => {
              enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
            
              //_setId(data.data.id);
              setOpenApplicationLogo(false);    
            },
            onError: (err: Error) => {          
              
              enqueueSnackbar( error?.message, { variant: 'error',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                  setOpenApplicationLogo(false);
            }
          });
      

    const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);

    const {data: _data, refetch} = useQuery<IApplicationSetup>(['ApplicationSetup', _id], () => retrieveEntity('ApplicationSetup',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'ApplicationSetup'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_APPOINTMENT_NOTIFICATION_MODE, Enum_PAYMENT_SOURCE, Enum_JOB_RETENTION_MODE, Enum_DEFAULT_LANGUAGE, Enum_CURRENCY, Enum_TICKET_RESOLUTION ] ));

    const watchWebhookToken = watch('webhookToken');
    const watchAppointmentValidationNotificationReportId = watch('appointmentValidationNotificationReportId');
      
    const [currentQueryType, setCurrentQueryType] = useState<QueryType>('policy');
    const handleClickSearchApplicationQuery = (queryType: QueryType) => (event: any) => {

      //const {mainBusinessApplicationId} = getValues();

      // if(mainBusinessApplicationId < 0) {
      //   enqueueSnackbar( t('Business application is not selected'), { variant: 'error',
      //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      //   return;
      // }


      setCurrentQueryType(queryType);
      //setOpenApplicationQueryFilter(true);
    }

    const rand = () => {
      return Math.random().toString(36).substring(2);
    };

    const generateToken = (len: number) : string => {
      return (rand() + rand() + rand() + rand()).substring(0,len);
    };


    const handleClickGenerateWebhookToken = (event: any) => {
      setValue('webhookToken', generateToken(26));
    }

    const handleClickGeneratApplicationApiToken = (event: any) => {
      setValue('applicationApiToken', generateToken(26));
    }

    const [openEntityExpression, setOpenEntityExpression] = useState(false);
    const [currentExpression, setCurrentExpression] = useState(''); 
    const [currentEntityName, setCurrentEntityName] = useState(''); 
    const [currentReturnType, setCurrentReturnType] = useState(''); 

    type ExpressionType = 'proforma-number' | 'billing-number' | 'delivery-number' | 'order-number' | 'expense-number';
    const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('proforma-number');

    const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
        entityName: string, returnType: string, expression: string) => {

      setCurrentExpressionType(expressionType);
      setCurrentEntityName(entityName);
      setCurrentReturnType(returnType);
      setCurrentExpression(expression);
      setOpenEntityExpression(true);
    }

    const handleClickOkExpression = async () => {

      const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
      if(!checkExpression){
        enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      const {syntaxOk, syntaxError, returnType} = checkExpression;
      if(!syntaxOk) {
        enqueueSnackbar( syntaxError , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }
      
      if(returnType !== currentReturnType) {
        enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
              return;
      }
          
      if( ['proforma-number', 'billing-number', 'delivery-number', 'order-number', 'expense-number'].includes(currentExpressionType)  ) {

        const proformaNumberExpression = currentExpressionType === 'proforma-number' ? currentExpression: getValues().proformaNumberExpression;
        const billingNumberExpression = currentExpressionType === 'billing-number' ? currentExpression: getValues().billingNumberExpression;
        const deliveryNumberExpression = currentExpressionType === 'delivery-number' ? currentExpression: getValues().deliveryNumberExpression;
        const purchaseOrderNumberExpression = currentExpressionType === 'order-number' ? currentExpression: getValues().purchaseOrderNumberExpression;
        const expenseNumberExpression = currentExpressionType === 'expense-number' ? currentExpression: getValues().expenseNumberExpression;
        
        setValue('proformaNumberExpression', proformaNumberExpression);
        setValue('billingNumberExpression', billingNumberExpression);
        setValue('deliveryNumberExpression', deliveryNumberExpression);        
        setValue('purchaseOrderNumberExpression', purchaseOrderNumberExpression);     
        setValue('expenseNumberExpression', expenseNumberExpression);     
      }

      setOpenEntityExpression(false);
    }

    type ReportUsage = 'appointment-validation';
    const [reportUsage, setReportUsage] = useState<ReportUsage>('appointment-validation');

    const handleClickSearchReport = (event: any, rptUsage: ReportUsage) => {  
      setReportUsage(rptUsage);    

      setOpenReportFilter(true);
    }

    const handleClickRemoveSharing = (event: any) => {
      setValue('appointmentValidationNotificationReportId', 0);
      setValue('appointmentValidationNotificationReportName', '');     
    }

    function openFileDialog() {
      (document as any).getElementById("file-upload").click();
    }
    
    const setFile = (_event: any) => {
      let f = _event.target.files![0];
    
      const fileSizeInKB = f.size / 1024;
      // Check if the file size is within your limit (e.g., 200 KB)
      if (fileSizeInKB > 200) {
        alert(t('File size should be less than 200 KB'));
        return;
      }
    
      var reader = new FileReader();
    
      reader.onload = function () {
    
          var binaryString = reader.result as string;
      
          const base64String = binaryString
                                      .replace('data:', '')
                                      .replace(/^.+,/, '');
    
          setValueLogo("base64Logo", base64String);
          
        };
    
        reader.onerror = function () {
          console.log("File load failed");
        };    
        reader.readAsDataURL(f);    
    };

    const [transmissionModes, setTransmissionModes]  = useState<{name: string, value: string}[]>([]);
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Application Setup'));  
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);      
      }, []);   

      useEffect(() => {
        
        setTransmissionModes([ {name: t('Sms'), value: 'sms'}, {name: t('WhatsApp'), value: 'whatsApp'}, {name: t('Email'), value: 'email'}
        ]);

      }, [t,i18n]);

      // useEffect( () => {
               
      //   // if(_id > 0)
      //   //   retrieveData('ApplicationSetup',_id, refetch);

      //     retrieveData('ApplicationSetup',999, refetch);  
      //   }, [/*_id*/] );

      // useEffect( () => {        
      //   refCurrentBusinessApplicationId.current = watchMainBusinessApplicationId;
      // }, [watchMainBusinessApplicationId]);    

      useEffect( () => {        
        setValue('webhookUrl', `${'_APP_'}/api/misc/hookData/v1/${watchWebhookToken}/receive-hookData` )
      }, [watchWebhookToken]);  
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
               
          // if(_id > 0)
          //   retrieveData('ApplicationSetup',_id, refetch);

            retrieveData('ApplicationSetup',999, refetch);  
          }, [/*_id*/] );

    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          
            _setId(_data.id);
            reset(_data);
        }
        }, [_data]);
    
      
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => { 
        if(!checkEntitySaveAuthorization('ApplicationSetup', _id)){
          setIsSaveLoading(false);
             return;
        }
          
        const data = getValues(); 
        const {whatsAppPassword} = data;

        if(data.companyName.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
           }
      
         mutate({...data, isPasswordChanged: whatsAppPassword !== '********' ,
         whatsAppPasswordMd5: md5(whatsAppPassword), whatsAppPassword: '',   });          
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ApplicationSetup', _id);
      }

      const [defColor, setDefColor] = useState<FieldPath<IAppTheme>>('background');

      const handleColorClick = (event: React.MouseEvent<HTMLElement>, defCol: FieldPath<IAppTheme> ) => {
        setDefColor(defCol);
        setAnchorEl(event.currentTarget); 
      }

      const [openApplicationTheme, setOpenApplicationTheme] = useState<boolean>(false);
      const actionDataDefineThemeApplicationSetup = async (event: MouseEvent<HTMLButtonElement>) => {     
        const applicationTheme = await getApplicationTheme();   
                
        if(!isFalsy(applicationTheme)) 
          resetTheme(applicationTheme);

        setOpenApplicationTheme(true);
      }

      const [openApplicationLogo, setOpenApplicationLogo] = useState<boolean>(false);
      const actionDataDefineLogoApplicationSetup = async (event: MouseEvent<HTMLButtonElement>) => {     
        const applicationLogo = await getApplicationLogo();   
                
        if(!isFalsy(applicationLogo)) 
          resetLogo(applicationLogo);

        setOpenApplicationLogo(true);
      }

      const handleOkDefineThemeApplicationSetup = async () => { 
        mutateAppplicationTheme(getValuesTheme());
      }

      const handleOkDefineLogoApplicationSetup = async () => { 
        mutateAppplicationLogo(getValuesLogo());
      }
      

      const [genericTheme, setGenericTheme] = useState<string>('default');
      const handleClickApplyTheme = (event: any) => {
        switch(genericTheme) {
          case 'default': resetTheme(defaultAppTheme);

          default: resetTheme(defaultAppTheme);
        }
      }
      

      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />                                

                                <Button id='btnActionDefineThemeApplicationSetup' onClick={actionDataDefineThemeApplicationSetup} sx={ {display:'none'}}  />
                                <Button id='btnActionDefineLogoApplicationSetup' onClick={actionDataDefineLogoApplicationSetup} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Name')} {...register('companyName')} inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                                
                                <Controller name='defaultLanguage' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="defaultLanguage"
                                          label={t('Language')} inputProps={ {readOnly: false}} focused >
                                          {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_DEFAULT_LANGUAGE ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Currency')} - ${t('Tax')}`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='defaultCurrencyCode' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="defaultCurrencyCode"
                                          label={`${t('Default')} - ${t('Currency')}`} inputProps={ {readOnly: false}} focused >
                                          {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_CURRENCY ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                  <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={`${t('Default')} - ${t('Tax')}`} sx={{width:'calc(25% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        //helperText={t('Number of differents')}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='defaultTaxRate'
                                  control={control}
                                />
                                <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Tax variable ?')}`}
                                    control={
                                    <Controller
                                        name='isTaxRateVariable'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />  
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Password complexity?')}`}
                                    control={
                                    <Controller
                                        name='isPasswordComplexityAllowed'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                                 
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Password duration')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Duration in days')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='passwordDuration'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Password rotation')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Number of differents')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='passwordRotation'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Max failed count')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Number of failures')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='maxAccessFailedCount'
                                control={control}
                              />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Access lockout duration')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Duration in minutes')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='accessLockoutDuration'
                                control={control}
                              />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Point value')} - ${t('Commission')}`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={t('Value of point')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        helperText={t('Value in currency of one point')}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='valueOfPoint'
                                  control={control}
                                />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={t('Min. disbursement point')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        helperText={t('Minimum number of point to disburse')}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='minimumDisbursementPointCount'
                                  control={control}
                                />
                                <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Auto disburse Commission ?')}`}
                                    control={
                                    <Controller
                                        name='isCommissionDisburseOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={t('Min. Com. Disbur. Amount')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        helperText={t('Minimum amount of commission to disburse')}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='minimumDisbursementCommissionAmount'
                                  control={control}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Treatment period')} ...`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Automatic ?')}`}
                                    control={
                                    <Controller
                                        name='isAutomaticIssueDate'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                              <Controller
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker 
                                    views={["year", "month"]}  
                                    label={t('Current period')}     
                                    onChange={onChange}                        
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:`calc(20% - 8px)`}} />}
                                  /> )}
                                  name='issuePeriod'          
                                control={control}
                              />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={t('End month closing delay')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        helperText={t('Closing delay in day(s)')}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='issueDateMaxDelayMonthClosing'
                                  control={control}
                                />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={t('End year closing delay')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        helperText={t('Closing delay in day(s)')}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='issueDateMaxDelayYearClosing'
                                  control={control}
                                />
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Close after delay ?')}`}
                                    control={
                                    <Controller
                                        name='isAutomaticClosedAfterDelay'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Sequence and numbering')} ...`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Proforma seq. by year ?')}`}
                                    control={
                                    <Controller
                                        name='resetProformaSequenceByYear'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Proforma seq. by month ?')}`}
                                    control={
                                    <Controller
                                        name='resetProformaSequenceByMonth'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="proformaNumberExpression" label={`${t('Expression')} - ${t('Proforma')}`}
                                    {...register('proformaNumberExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'proforma-number','Billing', 'string',getValues().proformaNumberExpression)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Billing seq. by year ?')}`}
                                    control={
                                    <Controller
                                        name='resetProformaSequenceByYear'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Billing seq. by month ?')}`}
                                    control={
                                    <Controller
                                        name='resetProformaSequenceByMonth'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="billingNumberExpression" label={`${t('Expression')} - ${t('Billing')}`}
                                    {...register('billingNumberExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'billing-number','Billing', 'string',getValues().billingNumberExpression)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Delivery seq. by year ?')}`}
                                    control={
                                    <Controller
                                        name='resetDeliverySequenceByYear'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Delivery seq. by month ?')}`}
                                    control={
                                    <Controller
                                        name='resetDeliverySequenceByMonth'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="deliveryNumberExpression" label={`${t('Expression')} - ${t('Delivery')}`}
                                    {...register('deliveryNumberExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'delivery-number','BillingDelivery', 'string',getValues().deliveryNumberExpression)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />
                            </Box>
                            
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Pur. Order seq. by year ?')}`}
                                    control={
                                    <Controller
                                        name='resetPurchaseOrderSequenceByYear'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Pur. Order seq. by month ?')}`}
                                    control={
                                    <Controller
                                        name='resetPurchaseOrderSequenceByMonth'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="purchaseOrderNumberExpression" label={`${t('Expression')} - ${t('Pur. Order')}`}
                                    {...register('purchaseOrderNumberExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'order-number','Expense', 'string',getValues().purchaseOrderNumberExpression)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Expense seq. by year ?')}`}
                                    control={
                                    <Controller
                                        name='resetExpenseSequenceByYear'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Expense seq. by month ?')}`}
                                    control={
                                    <Controller
                                        name='resetExpenseSequenceByMonth'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="expenseNumberExpression" label={`${t('Expression')} - ${t('Expense')}`}
                                    {...register('expenseNumberExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'expense-number','Expense', 'string',getValues().expenseNumberExpression)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />
                            </Box>
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                <EntityExpression entityName={currentEntityName} properties={[]} 
                                  expression={currentExpression} setExpression={setCurrentExpression} />
                              </FormDialog>  }
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Application API')} ... ${t('Integration')}`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <TextField sx={{width:'calc(50% - 8px)'}} id="applicationApiToken" label={t('Token')} 
                                  {...register('applicationApiToken')} inputProps={ {autoComplete: 'new-password', 
                                      style: {textTransform: 'none'}}}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickGeneratApplicationApiToken}>
                                          <TokenIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />       
                                <TextField sx={{width:'calc(50% - 8px)'}} id="websiteApplicationApiToken" label={`${('Token')} (${t('Website')})`} 
                                  {...register('websiteApplicationApiToken')} inputProps={ {autoComplete: 'new-password', 
                                      style: {textTransform: 'none'}}}
                                />                          
                            </Box>
                            
                            <Box sx={{ mt: 2.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`PayPal ${t('Configuration')} ...`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >      
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Live ?')}`}
                                    control={
                                    <Controller
                                        name='isPayPalLive'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                        
                              <TextField sx={{width:'calc(40% - 8px)'}} id="payPalClientID" label={t('Client ID')} {...register('payPalClientID')} 
                                inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                />
                              <TextField sx={{width:'calc(40% - 8px)'}} id="payPalSecretKey" label={t('Secret key')} {...register('payPalSecretKey')} 
                                inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >  
                              <Controller name='payPalPaymentSourceCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="payPalPaymentSourceCode"
                                      label={t('Payment source')} inputProps={ {readOnly: false}} focused >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_PAYMENT_SOURCE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Job schedule')} ...${t('Report')}`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Send SMS Batch?')}`}
                                    control={
                                    <Controller
                                        name='isSmsOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />   
                                <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Send WhatsApp Batch?')}`}
                                    control={
                                    <Controller
                                        name='isWhatsAppOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                             
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Schedule reports?')}`}
                                    control={
                                    <Controller
                                        name='isReportScheduleOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />   
                                <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                    label={`${t('Schedule features?')}`}                              
                                    control={
                                    <Controller
                                        name='isFeatureScheduleOn'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />                             
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                                <TextField sx={{width:'calc(25% - 8px)'}} id="notificationWhatsAppNumber1" label={t('WhatsApp number 1')} {...register('notificationWhatsAppNumber1')} />                            
                                <TextField sx={{width:'calc(25% - 8px)'}} id="notificationWhatsAppNumber2" label={t('WhatsApp number 2')} {...register('notificationWhatsAppNumber2')} />                            
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Service')} - ${t('Appointment')} ...`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                    label={`${t('Allow service without technician ?')}`}
                                    control={
                                    <Controller
                                        name='allowServiceWithoutTechnician'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Controller                                      
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TimePicker label={`${t('Min')} - ${t('Start time')}`}
                                      format="HH:mm:ss"    
                                      views={['hours', 'minutes', 'seconds']}     
                                      onChange={onChange}                        
                                      value={value}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:`calc(25% - 8px)`, textAlign: 'center'}} />}
                                    /> )}
                                  name={`minAppointmentStartTime`}          
                                  control={control}
                                    />
                              <Controller                                      
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TimePicker label={`${t('Max')} - ${t('Start time')}`}
                                      format="HH:mm:ss"    
                                      views={['hours', 'minutes', 'seconds']}     
                                      onChange={onChange}                        
                                      value={value}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:`calc(25% - 8px)`, textAlign: 'center'}} />}
                                    /> )}
                                  name={`maxAppointmentStartTime`}          
                                  control={control}
                                    />
                              <Controller name='onlineAppointmentNotificationMode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="onlineAppointmentNotificationMode"
                                      label={t('Online appointment notification mode')} inputProps={ {readOnly: false}} focused >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_APPOINTMENT_NOTIFICATION_MODE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >   
                              <Controller
                                
                                render={({ field }) => <TextField label={`${t('Report')} - ${t('Appointment')} - ${t('Validation')}`} {...field} 
                                      inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                      sx={{width:`calc(50% - 8px)`}}
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end"> 
                                            { (isFalsy(watchAppointmentValidationNotificationReportId) ||  watchAppointmentValidationNotificationReportId<=0) ?                                           
                                              <IconButton color="primary" onClick={(event) => handleClickSearchReport(event, 'appointment-validation')}>
                                                <ArrowDropDownCircleIcon />
                                              </IconButton> :
                                              <IconButton color="primary" onClick={handleClickRemoveSharing}>
                                                <RemoveCircleIcon />
                                              </IconButton>
                                            }                                                                                               
                                        </InputAdornment>
                                      )
                                    }} />}
                                name={`appointmentValidationNotificationReportName`}                      
                                control={control}
                              /> 
                              <Controller 
                                  name={`appointmentValidationNotificationTransmissionMode`}
                                  control={control} 
                                  defaultValue='default'
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="appointmentValidationNotificationTransmissionMode"
                                      label={t('Transmission mode')} inputProps={ {readOnly: false}} >
                                      { transmissionModes.map(({name, value}, idx) => (<MenuItem key={value} value={value}>{name}</MenuItem>))}                                        
                                    </TextField>
                                  )}
                              />  
                              <Controller name={`appointmentValidationNotificationRetentionMode`} 
                                    control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="retentionMode"
                                        label={t('Retention')} inputProps={ {readOnly: false}} >
                                        {enumItems && enumItems.filter(x => x.enumerationCode === Enum_JOB_RETENTION_MODE).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />  
                              { openReportFilter && <FormDialog open={openReportFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Report filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenReportFilter(false);}} onOk={()=> {setOpenReportFilter(false);}}  >
                                        <BasicTextFilterForm<IReport> {...basicFilterReport } />
                                </FormDialog> }  
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                    label={`${t('Contact of appointment is allowed for technician ?')}`}
                                    control={
                                    <Controller
                                        name='appointmentContactAllowedForTechnician'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                    label={`${t('All appointment is available for technician ?')}`}
                                    control={
                                    <Controller
                                        name='appointmentAllAvailableForTechnician'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Ticket')} ...${t('Default time to resolve ticket')}`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name={`ticketStartResolutionCode`} 
                                    control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="retentionMode"
                                        label={`${t('Start')} - ${t('Resolution')}`} inputProps={ {readOnly: false}} >
                                        {enumItems && enumItems.filter(x => x.enumerationCode === Enum_TICKET_RESOLUTION).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller name={`ticketEndResolutionCode`} 
                                    control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="retentionMode"
                                        label={`${t('End')} - ${t('Resolution')}`} inputProps={ {readOnly: false}} >
                                        {enumItems && enumItems.filter(x => x.enumerationCode === Enum_TICKET_RESOLUTION).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Default time to resolve ticket')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      helperText={t('Time in hours')}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='defaultTicketDurationHours'
                                control={control}
                              />
                            </Box>
                            { openApplicationTheme && <FormDialog open={openApplicationTheme} maxWidth='sm' height='70vh'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Application theme')} ...`} onCancel={()=> {setOpenApplicationTheme(false);}} 
                                  onClose={()=> {setOpenApplicationTheme(false);}} onOk={handleOkDefineThemeApplicationSetup}  >
                                <Stack flexDirection='column'>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'background')}
                                        sx={{ ml: 1, width: '32%', backgroundColor: getValuesTheme().background, color: theme.palette.getContrastText(getValuesTheme().background) }}>
                                        {t('Background')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'primary')}
                                        sx={{ ml: 1, width: '30%', backgroundColor: getValuesTheme().primary, color: theme.palette.getContrastText(getValuesTheme().primary)}}>
                                        {t('Primary')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'secondary')}
                                        sx={{ ml: 1, width: '30%', backgroundColor: getValuesTheme().secondary, color: theme.palette.getContrastText(getValuesTheme().secondary)}}>
                                        {t('Secondary')}
                                    </Button>                                    
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                  <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridHeader')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridHeader, color: theme.palette.getContrastText(getValuesTheme().gridHeader)}}>
                                        {t('Grid header')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridAlt')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridAlt, color: theme.palette.getContrastText(getValuesTheme().gridAlt)}}>
                                        {t('Grid alt')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridActiveRow')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridActiveRow, color: theme.palette.getContrastText(getValuesTheme().gridActiveRow)}}>
                                        {t('Grid active row')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'gridSelectedRows')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().gridSelectedRows, color: theme.palette.getContrastText(getValuesTheme().gridSelectedRows)}}>
                                        {t('Grid selected rows')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'menuButton')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().menuButton, color: theme.palette.getContrastText(getValuesTheme().menuButton)}}>
                                        {t('Menu button')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'menuIcon')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().menuIcon, color: theme.palette.getContrastText(getValuesTheme().menuIcon)}}>
                                        {t('Menu icon')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'dark')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().dark, color: theme.palette.getContrastText(getValuesTheme().dark)}}>
                                        {t('Dark')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'light')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().light, color: theme.palette.getContrastText(getValuesTheme().light)}}>
                                        {t('Light')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'success')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().success, color: theme.palette.getContrastText(getValuesTheme().success)}}>
                                        {t('Success')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'info')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().info, color: theme.palette.getContrastText(getValuesTheme().info)}}>
                                        {t('Info')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'danger')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().danger, color: theme.palette.getContrastText(getValuesTheme().danger)}}>
                                        {t('Danger')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'warning')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().warning, color: theme.palette.getContrastText(getValuesTheme().warning)}}>
                                        {t('Warning')}
                                    </Button>
                                  </Box>
                                  <Box sx={{ mt: 1, width: '100%' }}>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'muted')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().muted, color: theme.palette.getContrastText(getValuesTheme().muted)}}>
                                        {t('Muted')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'border')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().border, color: theme.palette.getContrastText(getValuesTheme().border)}}>
                                        {t('Border')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'inverse')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().inverse, color: theme.palette.getContrastText(getValuesTheme().inverse)}}>
                                        {t('Inverse')}
                                    </Button>
                                    <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => handleColorClick(event, 'shaft')}
                                        sx={{ ml: 1, width: '23%', backgroundColor: getValuesTheme().shaft, color: theme.palette.getContrastText(getValuesTheme().shaft)}}>
                                        {t('Shaft')}
                                    </Button>
                                  </Box>
                                  <Box display='flex' flexDirection='row' alignContent='space-around' justifyContent='center' sx={{ mt: 4.5, width: '100%' }} >                                     
                                      <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                              setGenericTheme(event.target.value);}} value={genericTheme} sx={{width:'calc(30% - 8px)'}} id="genericTheme"
                                            label={t('Generic theme')} inputProps={ {readOnly: false}} >
                                          <MenuItem value='default'>{t('Default')}</MenuItem>
                                          <MenuItem value='blue'>{t('Blue')}</MenuItem>
                                          <MenuItem value='green'>{t('Green')}</MenuItem>
                                          <MenuItem value='yellow'>{t('Yellow')}</MenuItem>
                                          <MenuItem value='red'>{t('Red')}</MenuItem>
                                          <MenuItem value='pink'>{t('Pink')}</MenuItem>
                                      </TextField>                                        
                                    <Button variant="outlined" onClick={handleClickApplyTheme} sx={{mb:2, ml: 1 }}>
                                      {t('Apply')}                              
                                    </Button>                                                   
                                  </Box>
                                  <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => {setAnchorEl(null);}}
                                  >
                                    <ChromePicker color={ get(getValuesTheme(), defColor ) as unknown as string } 
                                          onChange={(newColor: ColorResult) => {setValueTheme(defColor, newColor.hex); }} />
                                  </Popover>
                                </Stack>      
                              </FormDialog> }
                              { openApplicationLogo && <FormDialog open={openApplicationLogo} maxWidth='sm' height='70vh'
                                  okText={t('OK')} cancelText={t('Cancel')} title={`${t('Application theme')} ...`} onCancel={()=> {setOpenApplicationLogo(false);}} 
                                  onClose={()=> {setOpenApplicationLogo(false);}} onOk={handleOkDefineLogoApplicationSetup}  >
                                      <Stack flexDirection='column'>
                                        
                                        <Box sx={{ mt: 1, width: '100%' }} >
                                          <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                            accept="image/*" />
                                          <div key={'step.id'}>
                                            <Box
                                              component="img"
                                              sx={carouselImage}
                                              //src={`data:image/png;base64,${watchBase64Picture}`}
                                              src={isFalsy(watchBase64Logo)?`/images/product.jpg`:`data:image/png;base64,${watchBase64Logo}`}
                                              style={{ height: '350px', objectFit: 'cover', borderRadius: '2%'}}
                                              onClick={openFileDialog}
                                              //alt={step.id}
                                            ></Box>
                                          </div>
                                        </Box>
                                      </Stack>
                                  </FormDialog> }
                        </Stack>                        
                    </Grid>                  
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

